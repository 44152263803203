import { module } from 'modujs';
import Swiper, { A11y, EffectFade, Autoplay } from 'swiper';

export default class extends module {
    constructor(m) {
        super(m);

        this.$container = this.$('container')[0]
    }

    init() {
        this.carousel = new Swiper(this.$container, {
            modules: [
                A11y,
                EffectFade,
                Autoplay
            ],
            speed: 900,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            }
        });
    }

    destroy() {
        this.carousel.destroy()
    }
}
