import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.activeLink = null
        this.isReduced = false

        // Set array of links
        this.$links = Array.from(this.$('link')).map($link => ({
            el: $link,
            id: $link.hash.replace(/^#/, '')
        }))

        this.$triggerIndex = this.$('triggerIndex')[0]
        this.$triggerLabel = this.$('triggerLabel')[0]

        this.events = {
            click: {
                'trigger': 'open',
                'overlay': 'close',
                'link': 'scrollTo',
            }
        }
    }

    init() {
        this.resize()
        window.addEventListener('resizeEnd', this.onResize = () => this.resize())
    }

    getLink(id) {
        return this.$links.find(link => link.id == id)
    }

    open() {
        this.isOpen = true
        this.el.classList.add('is-open')
    }

    close() {
        this.isOpen = false
        this.el.classList.remove('is-open')
    }

    scrollTo(e) {
        e.preventDefault()
        const $target = e.curTarget
        const id = $target.hash
        this.call('scrollTo', {target: id}, 'Scroll');


        if(this.isOpen) {
            this.close()
        }
    }

    trigger(e) {
        const id = e.obj.el.id

        if(e.way === 'enter') {

            if(this.activeLink !== null) {
                this.activeLink.el.classList.remove('is-active')
            }

            this.activeLink = this.getLink(id)
            this.activeLink.el.classList.add('is-active')

            // Update trigger
            this.$triggerIndex.innerHTML = this.activeLink.el.dataset.index
            this.$triggerLabel.innerHTML = this.activeLink.el.dataset.label

        } else if(e.way === 'exit' && id === this.activeLink.id) {
            this.activeLink.el.classList.remove('is-active')
            this.activeLink = null
        }
    }

    toggleVisibility(e) {

        if(e.way === 'enter') {
            this.el.classList.add('is-hidden')
        } else if(e.way === 'exit') {
            this.el.classList.remove('is-hidden')
        }
    }

    onScroll(scrollY) {

        if(!this.mobileLayout) {
            return
        }

        if(this.isOpen) {
            this.close()
        }

        if(scrollY + 1 < this.previousScroll && this.isReduced) {
            this.isReduced = false
            this.el.classList.remove('is-reduced')
        } else if(scrollY - 1 > this.previousScroll && !this.isReduced) {
            this.isReduced = true
            this.el.classList.add('is-reduced')
        }

        this.previousScroll = scrollY
    }

    resize() {
        this.mobileLayout = getComputedStyle(this.el).getPropertyValue('--mobile-layout') == 1
        console.log(this.mobileLayout)
    }

    destroy() {
        window.removeEventListener('resizeEnd', this.onResize)
    }
}
