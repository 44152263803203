import { module } from 'modujs';
import modularLoad from 'modularload';
import { html, isDebug } from '../utils/environment';

export default class extends module {
    init() {
        this.load = new modularLoad({
            enterDelay: 900,
            exitDelay: 450,
            loadingClass: 'is-transitioning',
            loadedClass: 'is-transitioned',
            transitions: {
                customTransition: {}
            }
        });

        this.load.scrollTo = null;
        this.load._getClickOptions = this.load.getClickOptions;
        this.load.getClickOptions = function (link) {
            this.scrollTo = link.getAttribute('data-scrollto-href');

            this._getClickOptions(link);
        }

        this.handleGtmEvents();
        this.handleInternalLinks();

        this.load.on('loading', (transition, oldContainer) => {
            this.call('closeNav', null, 'Nav')
            this.call('closeGetInTouch', null, 'GetInTouch')
            this.call('start', 'Transition');
        });

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        });

        this.load.on('loaded', (transition, newContainer) => {
            this.call('stop', 'Transition');
        });

        this.load.on('ready', () => {
            if (this.load.scrollTo) {
                this.call('scrollTo', { target: this.load.scrollTo }, 'Scroll');
                this.load.scrollTo = null;
            }

            this.handleGtmEvents();
            this.handleInternalLinks();
            this.handleZoomInfo();
        });
    }

    handleInternalLinks() {
        const main = document.getElementById('main');

        if (main) {
            main.querySelectorAll('a[href^="#"]:not([data-scroll-to])').forEach((elem) => {
                elem.setAttribute('data-scroll-to', '');
            });
        }
    }

    handleGtmEvents() {
        this.$('gtm-event').forEach((script) => {
            const jsonText = script.innerHTML;

            if (jsonText) {
                try {
                    const jsonData = JSON.parse(jsonText)
                    if (jsonData) {
                        isDebug && console.log('GTM Event:', jsonData);
                        Array.isArray(window.dataLayer) && window.dataLayer.push(jsonData);
                    }
                } catch (error) {
                    isDebug && console.error('GTM Error:', error)
                }
            }
        });
    }

    handleZoomInfo() {
        window?.zitag?.GetListOfEntitlements()
    }

    handleLazyAttributes(element) {
        this.load.loadEls(element);
    }

    goTo(href) {
        this.load.goTo(href);
    }
}
