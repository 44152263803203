import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'button': 'toggleNav',
                'getInTouch': 'toggleGetInTouch'
            }
        }
    }

    toggleNav() {
        html.classList.toggle('has-navOpen');
    }

    toggleGetInTouch() {
        html.classList.toggle('has-getInTouchOpen');
    }
}
