import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            change: 'filter'
        }
    }

    filter(event) {
        const filter = event.target;

        if (!filter.value) {
            return;
        }

        try {
            const url = new URL(filter.value);
            this.call('pushEventData', {
                eventCategory: 'Nav_Journal',
                eventAction: 'Categories journal',
                eventLabel: filter.querySelector(`[value="${filter.value}"]`).text,
            }, 'Gtm');
            window.location = url;
        } catch (e) {
            const query = `?category=${encodeURIComponent(filter.value)}`;
            console.log('Query:', query);
            window.location.search = query;
        }
    }
}
