import { module } from 'modujs';
import gsap from 'gsap';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);

        this.active = false
        this.inited = false
        this.direction = '';

        this.$grid = this.$('grid')[0]
        this.$gridItems = Array.from(this.$('grid-item'));
    }

    init() {
        this.compute()

        this.checkResize = () => {
            if(!this.resizeTick) {
                this.resizeTick = true
                requestAnimationFrame(() => {
                    this.compute()
                    this.resizeTick = false
                })
            }
        }
        window.addEventListener('resize', this.checkResize)

        this.isMobile = !html.classList.contains('has-scroll-smooth')
    }

    compute() {
        this.vw = window.innerWidth
        this.vh = window.innerHeight

        this.call('forceElementDetect', this.el, 'Scroll');
    }

    setTimeline() {
        this.tl?.kill?.();

        this.tl = gsap.timeline({});

        this.tl.set([this.$gridItems], { clearProps: 'all' });

        const gridBCR = this.$grid.getBoundingClientRect();
        const targetCoords = { // Center of the grid
            left: gridBCR.width/2,
            top: gridBCR.height/2
        };

        const duration = 1;
        // Loop other gridItems to animate each individually
        for(let i = 0; i < this.$gridItems.length; i++) {
            const item = this.$gridItems[i];

            // Compute offsetToTarget
            const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = item; // Extract properties for better readability
            const offsetToTarget = {
                x: targetCoords.left - offsetLeft - offsetWidth/2,
                y: targetCoords.top - offsetTop - offsetHeight/2
            };

            // Move the item to the target
            this.tl.to(item, {
                ...offsetToTarget,
                duration,
                ease: 'power2.inOut'
            }, i * 0.1);
        }

        this.tl.progress(0); // Set progress to 0 or old progress value (if we're in a resize event)
        this.tl.pause(); // Very important to pause the timeline, we don't want it to play on its own: it's meant to be controlled by `onScrollProgress` only
    }

    trigger(args) {
        if(this.isMobile) return

        let { obj, way } = args
        if(obj.el != this.el) return

        this.direction = args.direction

        if(way == 'enter') {
            this.setTimeline();

            this.obj = obj
            this.active = true

            if(!this.inited) {
                this.inited = true
                this.onScroll({ scroll: { x: 0, y: 0 } })
            }
        } else {
            this.active = false
        }
    }

    onScroll(args) {
        if(!this.active) return

        let height = this.obj.bottom - this.obj.top;
        let start = this.obj.top
        let end = start + height - this.vh
        let progress = (args.scroll.y - start) / (end - start)
        progress = Math.min(Math.max(0, progress), 1)

        this.el.style.setProperty('--progress', progress);
        this.tl?.progress?.(progress)
    }

    destroy() {
        super.destroy()

        window.removeEventListener('resize', this.checkResize)
    }
}
