import { module } from 'modujs';

const STATE = {
    IDLE:       'IDLE',       // Form is ready
    PROCESSING: 'PROCESSING', // Form is being processed
    TESTING:    'TESTING',    // Form is being tested
    SENDING:    'SENDING',    // Form is being sent and awaiting response
    INVALID:    'INVALID',    // Form is invalid (HTTP 400)
    ERRORED:    'ERRORED',    // Form can not be processed (HTTP 500)
    COMPLETED:  'COMPLETED',  // Form was processed successfully (HTTP 201)
}

const CLASS = {
    IS_BUSY:    'is-busy',
    IS_LOADING: 'is-loading',
    IS_TESTING: 'is-testing',
    IS_SENDING: 'is-sending',
    IS_SUCCESS: 'is-success',
    IS_INVALID: 'is-invalid',
    HAS_ERROR:  'has-error',
}

export default class extends module {
    constructor(m) {
        super(m)

        this.events = {
            change: 'handleChange',
            submit: 'handleSubmit',
        }

        this.submitEvent = null

        this.state = STATE.IDLE
        this.busy  = false
    }

    init() {
        this.formID         = this.el.id.startsWith('c-form-') ? this.el.id.slice(7) : 0
        this.formAction     = this.getData('action')
        this.confirmMessage = this.getData('confirm-message')
        this.invalidMessage = this.getData('invalid-message')
        this.errorMessage   = this.getData('error-message')

        this.hasRecaptchaField = this.getData('recaptcha') || false
        if (this.hasRecaptchaField) {
            this.prepareRecaptcha()
        }
    }

    /**
     * Change the current state of the form.
     *
     * @param {String} state - The form state.
     */
    setState(state) {
        // Always assume the form is busy
        this.busy  = true
        this.state = STATE.PROCESSING

        this.el.classList.add(CLASS.IS_BUSY)
        this.lockForm()

        // And assume we are idle
        this.el.classList.remove(CLASS.IS_LOADING)
        this.el.classList.remove(CLASS.IS_TESTING)
        this.el.classList.remove(CLASS.IS_SENDING)
        this.el.classList.remove(CLASS.IS_SUCCESS)
        this.el.classList.remove(CLASS.IS_INVALID)
        this.el.classList.remove(CLASS.HAS_ERROR)

        switch (state) {
            case STATE.IDLE:
                this.busy  = false
                this.state = state

                this.el.classList.remove(CLASS.IS_BUSY)

                this.setFeedback()
                this.unlockForm()
                break

            case STATE.PROCESSING:
                this.state = state
                break

            case STATE.TESTING:
                this.state = state

                this.el.classList.add(CLASS.IS_TESTING)
                break

            case STATE.SENDING:
                this.state = state

                this.el.classList.add(CLASS.IS_SENDING)
                break

            case STATE.COMPLETED:
                this.busy  = false
                this.state = state

                this.el.classList.remove(CLASS.IS_BUSY)
                this.el.classList.add(CLASS.IS_SUCCESS)

                this.el.reset()
                this.unlockForm()
                break

            case STATE.INVALID:
                this.busy  = false
                this.state = state

                this.el.classList.remove(CLASS.IS_BUSY)
                this.el.classList.add(CLASS.IS_INVALID)

                this.unlockForm()
                break

            case STATE.ERRORED:
                this.busy  = false
                this.state = state

                this.el.classList.remove(CLASS.IS_BUSY)
                this.el.classList.add(CLASS.HAS_ERROR)

                this.setFeedback(window.itiData.l10n.errorTryLater)
                this.disableFormFields()
                break

            default:
                this.busy  = false
                this.state = STATE.ERRORED

                this.el.classList.remove(CLASS.IS_BUSY)
                this.el.classList.add(CLASS.HAS_ERROR)

                this.setFeedback(window.itiData.l10n.unknownError)
                this.disableFormFields()
                break
        }
    }

    /**
     * @param {String} [message] Leave blank to clear existing message.
     */
    setFeedback(message) {
        if (!message) {
            message = ''
        }

        this.$('feedback')[0].innerHTML = message

        this.updateScroll()
    }

    /**
     * @return {Boolean}
     */
    isBusy() {
       return this.el.classList.contains(CLASS.IS_BUSY)
    }

    /**
     * @return {Boolean}
     */
    isSending() {
       return this.el.classList.contains(CLASS.IS_SENDING)
    }

    /**
     * Ensure the reCAPTCHA API is available.
     *
     * @see GF_Field_CAPTCHA::ensure_recaptcha_js()
     */
    loadRecaptcha() {
        const js = document.getElementsByTagName('script')[0]
        const re = document.createElement('script')

        re.id    = 'gform_recaptcha-js'
        re.async = true
        re.src   = 'https://www.google.com/recaptcha/api.js?hl=' + document.documentElement.lang + '&render=explicit'

        js.parentNode.insertBefore(re, js)
    }

    /**
     * Ensure the reCAPTCHA API is available.
     *
     * @see GF_Field_CAPTCHA::ensure_recaptcha_js()
     */
    prepareRecaptcha() {
        if (!document.getElementById('gform_recaptcha-js') && (!window.grecaptcha || !window.grecaptcha.render)) {
            this.loadRecaptcha()
        }

        this.gfRecaptchaAttempt = 0;
        this.gfRecaptchaPoller  = window.setInterval(() => {
            this.gfRecaptchaAttempt++;

            if (this.gfRecaptchaAttempt === 50) {
                clearInterval( this.gfRecaptchaPoller )
                this.hasRecaptchaField = false
                return
            }

            if (!window.grecaptcha || !window.grecaptcha.render) {
                return
            }

            clearInterval( this.gfRecaptchaPoller )
            this.renderRecaptcha()
        }, 100)
    }

    /**
     * Prepare the reCAPTCHA element(s).
     *
     * @see gravityforms.js:renderRecaptcha()
     */
    renderRecaptcha() {
        const elem = this.getRecaptchaField()
        if (!elem) {
            return
        }

        const params = {
            'sitekey':  elem.getAttribute('data-sitekey'),
            'theme':    elem.getAttribute('data-theme'),
            'tabindex': elem.getAttribute('data-tabindex'),
        }

        if (elem.hasChildNodes()) {
            return
        }

        if (elem.getAttribute('data-stoken')) {
            params.stoken = elem.getAttribute('data-stoken')
        }

        let callback = false
        if (elem.getAttribute('data-size') === 'invisible') {
            callback = (token) => {
                if (token) {
                    this.submitForm()
                }
            }
        }

        if (callback) {
            params.callback = callback
        }

        elem.setAttribute('data-widget-id', grecaptcha.render(elem, params))

        if (params.tabindex) {
            elem.querySelector('iframe').setAttribute('tabindex', params.tabindex)
        }
    }

    /**
     * Retrieves the reCAPTCHA element.
     *
     * @return {Element}
     */
    getRecaptchaField() {
        const recaptcha = this.el.querySelector('.c-form_recaptcha')
        if (recaptcha) {
            return recaptcha
        }
    }

    /**
     * Reset the reCAPTCHA element.
     */
    resetRecaptcha() {
        const recaptcha = this.getRecaptchaField()
        if (recaptcha && recaptcha.getAttribute('data-widget-id')) {
            grecaptcha.reset(recaptcha.getAttribute('data-widget-id'))
        }
    }

    /**
     * @return {Boolean}
     */
    isFormLocked() {
        return !! this.el.disabled
    }

    canElemReadOnly(elem) {
        switch (elem.type) {
            case 'button':
            case 'checkbox':
            case 'radio':
            case 'reset':
            case 'select-one':
            case 'select-multiple':
            case 'submit':
                return false
        }

        return true
    }

    /**
     * Lock form.
     */
    lockForm() {
        this.el.setAttribute('disabled', true)
        this.el.disabled = true
    }

    /**
     * Unlock form.
     */
    unlockForm() {
        this.el.removeAttribute('disabled')
        this.el.disabled = false
    }

    /**
     * Disable edition of form fields.
     */
    disableFormFields() {
        this.el.setAttribute('disabled', true)
        this.el.disabled = true

        Array.from(this.el.elements).forEach((elem) => {
            elem.setAttribute('disabled', 'disabled')
            elem.disabled = true
        })
    }

    /**
     * Enable edition of form fields.
     */
    enableFormFields() {
        Array.from(this.el.elements).forEach((elem) => {
            elem.removeAttribute('disabled')
            elem.disabled = false
        })
    }

    /**
     * @param {Event} event - The submit event.
     */
    handleChange(event) {
        const control = event.target

        if (control.type === 'radio') {
            const wrapper = control.closest('.c-form_item')
            if (wrapper) {
                const other = wrapper.querySelector('input[type="text"]')
                if (other) {
                    other.disabled = (control.value !== 'gf_other_choice')
                }
            }
        }
    }

    /**
     * Custom submit
     *
     * @param {Event} event - The submit event.
     */
    handleSubmit(event) {
        event.preventDefault()

        if (this.isBusy() || this.isFormLocked()) {
            console.warn('Form is busy or locked')
            return false
        }

        this.submitEvent = event

        this.setFeedback()
        this.setState(STATE.PROCESSING)

        try {
            const recaptcha = this.getRecaptchaField()
            if (recaptcha && recaptcha.getAttribute('data-size') === 'invisible') {
                this.setState(STATE.TESTING)

                // Check for the verified invisible captcha token first.
                let recaptchaResponse = this.$('input[name="g-recaptcha-response"]')
                if (recaptchaResponse.length === 0) {
                    recaptchaResponse = recaptcha.querySelector('.g-recaptcha-response')
                }

                const token = recaptchaResponse.value
                if (!token) {
                    // Execute the invisible captcha.
                    grecaptcha.execute(recaptcha.getAttribute('data-widget-id'))
                }
            } else {
                this.setState(STATE.TESTING)

                this.submitForm()
            }
        } catch (error) {
            console.error('[ITI]', error)
            this.setState(STATE.ERRORED)
            this.setFeedback(window.itiData.l10n.errorTryLater)
        }
    }

    submitForm() {
        if (this.isSending()) {
            console.warn('Form is already sending')
            return false
        }

        if (!this.submitEvent) {
            throw new Error('Missing submit event object')
        }

        this.setState(STATE.SENDING)

        let isInvalid = false
        let hasError  = false

        const event    = this.submitEvent
        const form     = event.target
        const formUrl  = this.formAction || form.action
        const formData = new FormData(form)

        fetch(formUrl, {
            method: form.method,
            body:   formData,
        }).then(response => {
            if (response.status >= 400 && response.status <= 499) {
                isInvalid = true
            } else if (response.status >= 500 && response.status <= 599) {
                hasError = true
            }

            return response.json()
        }).then(response => {
            this.resetRecaptcha()

            if (isInvalid) {
                this.setState(STATE.INVALID)

                let messages = ''

                if (
                    Array.isArray(response.errors) &&
                    response.errors.length
                ) {
                    response.errors.forEach((error) => {
                        if (error.message) {
                            let message = error.message;

                            if (error.input_id) {
                                message = '<label for="' + error.input_id + '">' + message + '</label>'
                            }

                            messages += '<p>' + message + '</p>'
                        }
                    })
                }

                if (response.results.validation_messages) {
                    const validation_messages = response.results.validation_messages
                    for (let [ fieldID, message ] of Object.entries(validation_messages)) {
                        if (message) {
                            if (fieldID) {
                                message = `<label for="input_${this.formID}_${fieldID}">${message}</label>`
                            }

                            messages += '<p>' + message + '</p>'
                        }
                    }
                }

                this.setFeedback(messages || this.invalidMessage)

                const submitCancelEvent = new CustomEvent('submitcancel', {
                    bubbles: true,
                    detail: {
                        results: response.results
                    }
                });
                this.el.dispatchEvent(submitCancelEvent)
            } else if (hasError) {
                this.setState(STATE.ERRORED)
                this.setFeedback(this.errorMessage)

                const submitCancelEvent = new CustomEvent('submitcancel', {
                    bubbles: true,
                    detail: {
                        results: response.results
                    }
                });
                this.el.dispatchEvent(submitCancelEvent)
            } else {
                this.setState(STATE.COMPLETED)
                this.setFeedback(this.confirmMessage)

                const submitEndEvent = new CustomEvent('submitend', {
                    bubbles: true,
                    detail: {
                        results: response.results
                    }
                });
                this.el.dispatchEvent(submitEndEvent)

                this.call('handleSubmit', event, 'Gtm')

                if (response.results) {
                    const results = response.results
                    if (results.confirmation_type) {
                        if (results.confirmation_type === 'redirect') {
                            if (results.confirmation_redirect) {
                                try {
                                    const url = new URL(results.confirmation_redirect)
                                    this.call('goTo', url, 'Load');
                                    return
                                } catch (e) {
                                    console.error('[ITI]', 'Failed to redirect to:', results.confirmation_redirect)
                                }
                            }
                        } else if (results.confirmation_message) {
                            try {
                                const wrap = document.createElement('div')
                                wrap.innerHTML = results.confirmation_message.trim()
                                const elem = wrap.firstElementChild

                                const prnt = this.el.parentNode
                                prnt.replaceChild(elem, this.el)
                                this.el = elem
                                this.el.focus()

                                this.mDestroy()
                                this.destroy()

                                this.call('update', this.el, 'app')

                                this.call('scrollTo', { target: prnt }, 'Scroll')

                                this.updateScroll()
                                return
                            } catch (e) {
                                console.error('[ITI]', 'Failed to replace form with:', results.confirmation_message)
                            }
                        }
                    }
                }
            }

            this.submitEvent = null
        }).catch(error => {
            console.error('[ITI]', error)
            this.setState(STATE.ERRORED)
            this.setFeedback(window.itiData.l10n.errorTryLater)

            const submitCancelEvent = new CustomEvent('submitcancel', {
                detail: { error }
            });
            this.el.dispatchEvent(submitCancelEvent)
        })
    }

    /**
     * In case of use of locomotive-scroll, we need to update page height
     */
    updateScroll() {
        requestAnimationFrame(() => {
            this.call('update', 'Scroll')
        })
    }

    destroy() {
        clearInterval( this.gfRecaptchaPoller )
    }
}
