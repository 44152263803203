import { module } from 'modujs';
import { isDebug } from '../utils/environment';

const MAX_TIME_LIMIT = 20;
const TIME_LEFT_TAG  = '{{ timeLeft }}';

/**
 * Downloader
 *
 * @todo Clear timeout if the visitor clicked on the download button
 *     before the timer elapsed.
 *
 * Credit: Mateusz Rybczonec (https://codepen.io/geoffgraham/pen/yLywVbW)
 */
export default class extends module {
    init() {
        console.group('Download.init');

        if (!this.el.href || !this.el.hasAttribute('download')) {
            console.error('[ITI]', 'Download: Invalid downloadable anchor element; skipping module setup.');
            console.groupEnd();
            return;
        }

        let refresh = this.getData('refresh');
        if (refresh) {
            refresh = parseInt(refresh);
            if (isNaN(refresh)) {
                console.error('[ITI]', 'Download: Page refresh must be an integer, received:', (typeof this.getData('refresh')));
            } else {
                isDebug && console.log('[ITI]', `Download: Refreshing page in ${refresh} seconds`);
                setTimeout(() => {
                    window.location.reload()
                }, (refresh * 1000));
            }
        }

        this.loadDelay = this.getData('delay');
        this.loadDelay = parseInt(this.loadDelay);
        if (isNaN(this.loadDelay)) {
            if (localStorage.getItem('intro')) {
                this.loadDelay = 1800;
            } else {
                this.loadDelay = 9100;
            }
        }

        this.timeLimit = this.getData('countdown');
        if (!this.timeLimit) {
            console.groupEnd();
            return;
        }

        this.timeLimit = parseInt(this.timeLimit);
        if (isNaN(this.timeLimit)) {
            console.error('[ITI]', 'Download: Delay must be an integer, received:', (typeof this.getData('countdown')));
            console.groupEnd();
            return;
        }

        if (this.timeLimit > MAX_TIME_LIMIT) {
            isDebug && console.warn('[ITI]', 'Download: Using maximum delay:', MAX_TIME_LIMIT);
            this.timeLimit = MAX_TIME_LIMIT;
        }

        this.timerElement = this.getData('timer-target');
        if (this.timerElement) {
            this.timerElement = document.querySelector(this.timerElement);
        }

        if (!this.timerElement) {
            this.timerElement = this.$('download')[0];
        }

        if (!this.timerElement) {
            console.error('[ITI]', 'Download: Invalid timer element; skipping module setup.');
            console.groupEnd();
            return;
        }

        this.timeLeft       = this.timeLimit;
        this.timePassed     = 0;
        this.timerInterval  = null;
        this.timerState     = this.getData('timer-state');
        this.timerMessage   = this.getData('wait-message') || null;
        this.timesUpMessage = this.getData('done-message') || null;

        if (this.timerMessage && this.timerMessage.indexOf(TIME_LEFT_TAG) === -1) {
            console.error('[ITI]', `Download: Delay message must contain template tag: ${TIME_LEFT_TAG}`);
            this.timerMessage = null;
        }

        this.prepareTimer()

        if (this.timerState === 'start') {
            // If the theme_url is unavailable, the intro will be skipped, in which case the countdown will begin.
            // If the intro is presentable, delay the countdown based on whether this is the visitor's first time.
            // Added an extra second delay to account for the transition time.
            if (!window.itiData || !window.itiData.theme_url) {
                this.startTimer()
            } else {
                setTimeout(() => {
                    this.startTimer();
                }, this.loadDelay + 1000);
            }
        }

        console.groupEnd();
    }

    onTimesUp() {
        clearInterval(this.timerInterval);

        if (this.timerElement && this.timesUpMessage) {
            this.timerElement.innerHTML = this.timesUpMessage;
        }

        if (this.el.href && this.el.hasAttribute('download')) {
            this.el.click()
        }
    }

    prepareTimer() {
        if (this.timerElement) {
            this.timerElement.innerHTML = this.formatTime(this.timeLeft);
        }
    }

    startTimer() {
        this.timerInterval = setInterval(() => {
            this.timePassed = this.timePassed += 1;
            this.timeLeft   = this.timeLimit - this.timePassed;

            if (this.timerElement) {
                this.timerElement.innerHTML = this.formatTime(this.timeLeft);
            }

            if (this.timeLeft === 0) {
                this.onTimesUp();
            }
        }, 1000);
    }

    formatTime(time) {
        if (this.timerElement && this.timerMessage) {
            return this.timerMessage.replace(TIME_LEFT_TAG, time);
        }

        return time;
    }
}


