import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                header: 'toggleSection'
            }
        }
    }

    init() {
        if (this.getData('open')) {
            this.$('section')[0].classList.add('is-open');
        }
    }

    toggleSection(e) {
        const target = e.curTarget;
        const section = this.parent('section', target);
        const main = this.$('main', target);

        if (section.classList.contains('is-open')) {
            section.classList.remove('is-open');
        } else {
            if (this.$('section.is-open').length) {
                this.$('section.is-open')[0].classList.remove('is-open');
            }

            section.classList.add('is-open');
            this.call('scrollTo', {target: section}, 'Scroll');
        }

        setTimeout(() => {
            this.call('update', 'Scroll');
        }, 100);
    }
}
