import { module } from 'modujs';
import { isLocalStorageAvailable, isSessionStorageAvailable } from '../utils/is';
import * as CookieConsent from 'vanilla-cookieconsent';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'hide':                   'hideModals',
                'show-preferences-modal': 'showPreferencesModal',
            },
        };
    }

    init() {
        const settings = window.itiData.cookie_consent || {};

        if (!settings.categories || Object.keys(settings.categories).length === 0) {
            console.warn('[CookieConsent]', 'No categories configured.');
            return;
        }

        settings.categories = this.prepareCategories(settings.categories);

        CookieConsent.run(Object.assign({
            onConsent: () => {
                this.toggleCategoryAnalytics();
                this.toggleCategoryTargeting();
            },
            onChange: ({ changedCategories }) => {
                if (changedCategories.includes('analytics')) {
                    this.toggleCategoryAnalytics();
                }

                if (changedCategories.includes('targeting')) {
                    this.toggleCategoryTargeting();
                }
            },
        }, settings));
    }

    hideModals() {
        CookieConsent.hidePreferences();
        CookieConsent.hide();
    }

    prepareCategories(categories) {
        for (const [ categoryName, categoryData ] of Object.entries(categories)) {
            if (
                categoryData?.autoClear?.cookies &&
                Array.isArray(categoryData.autoClear.cookies) &&
                categoryData.autoClear.cookies.length > 0
            ) {
                categoryData.autoClear.cookies.map((cookie) => {
                    if (
                        cookie?.name &&
                        typeof cookie.name === 'string'
                    ) {
                        const found = cookie.name.match(/^\/(.+)\/([a-z]+)?$/);
                        if (found) {
                            cookie.name = new RegExp(found[1], found[2]);
                        }
                    }

                    return cookie;
                });

                categories[categoryName].autoClear.cookies = categoryData.autoClear.cookies;
            }
        }

        return categories;
    }

    eraseStorageItems(cookies) {
        let localStorageKeys;
        let sessionStorageKeys;

        for (const { name: cookieName } of cookies) {
            if (isLocalStorageAvailable()) {
                localStorageKeys ??= getAllStorageKeys(window.localStorage);

                const foundCookies = findMatchingCookies(localStorageKeys, cookieName);
                for (const foundCookie of foundCookies) {
                    window.localStorage.removeItem(foundCookie);
                }
            }

            if (isSessionStorageAvailable()) {
                sessionStorageKeys ??= getAllStorageKeys(window.sessionStorage);

                const foundCookies = findMatchingCookies(sessionStorageKeys, cookieName);
                for (const foundCookie of foundCookies) {
                    window.sessionStorage.removeItem(foundCookie);
                }
            }
        }
    }

    showPreferencesModal() {
        CookieConsent.showPreferences();
    }

    toggleCategoryAnalytics() {
        const isAccepted = CookieConsent?.acceptedCategory('analytics');

        window.dataLayer?.push([ 'consent', 'update', {
            'analytics_storage': (isAccepted ? 'granted' : 'denied'),
        } ]);

        if (!isAccepted) {
            const cookies = CookieConsent.getConfig('categories')?.analytics?.autoClear?.cookies;
            if (Array.isArray(cookies) && cookies.length) {
                this.eraseStorageItems(cookies);
            }
        }
    }

    toggleCategoryTargeting() {
        const isAccepted = CookieConsent?.acceptedCategory('targeting');

        window.dataLayer?.push([ 'consent', 'update', {
            'ad_storage': (isAccepted ? 'granted' : 'denied'),
        } ]);

        if (!isAccepted) {
            const cookies = CookieConsent.getConfig('categories')?.targeting?.autoClear?.cookies;
            if (Array.isArray(cookies) && cookies.length) {
                this.eraseStorageItems(cookies);
            }
        }
    }
}

/**
 * This function is copied from `findMatchingCookies()` from
 * {@link https://github.com/orestbida/cookieconsent/blob/v3.0.0-rc.17/src/utils/cookies.js vanilla-cookieconsent}.
 *
 * @param {string[]} allCookies
 * @param {string}   cookieName
 */
function findMatchingCookies(allCookies, cookieName) {
    if (cookieName instanceof RegExp) {
        return allCookies.filter((cookie) => cookieName.test(cookie));
    } else {
        const cookieIndex = allCookies.indexOf(cookieName);
        return cookieIndex > -1
            ? [ allCookies[cookieIndex] ]
            : [];
    }
}

/**
 * Returns array with all the cookie names.
 *
 * This function is based on `getAllCookies()` from
 * {@link https://github.com/orestbida/cookieconsent/blob/v3.0.0-rc.17/src/utils/cookies.js vanilla-cookieconsent}.
 *
 * @param   {Storage} storage
 * @param   {?RegExp} [regex]
 * @returns {string[]}
 */
function getAllStorageKeys(storage, regex) {
    /**
     * @type {string[]}
     */
    const cookieNames = [];

    /**
     * Save only the item names
     */
    for (const name of Object.keys(storage)) {
        if (regex) {
            try {
                regex.test(name) && cookieNames.push(name);
            // eslint-disable-next-line no-empty
            } catch (e) {}
        } else {
            cookieNames.push(name);
        }
    }

    return cookieNames;
}
