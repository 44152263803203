import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html, isDebug } from './utils/environment';
import debounce from './utils/debounce';

const app = new modular({
    modules: modules
});

window.onload = (event) => {
    const $style = document.getElementById('iti-theme-app-css');

    // Add resizeEnd event to window
    const resizeEndEvent = new CustomEvent('resizeEnd');
    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEndEvent)
    }, 200, false))

    if ($style.isLoaded) {
        init();
    } else {
        $style.addEventListener('load', (event) => {
            init();
        });
    }
};

function init() {
    app.init(app);

    globals();

    html.classList.add('is-loaded');

    if (!window.itiData || !window.itiData.theme_url) {
        setReady()
    } else {
        // Commented for the first year
        // if (localStorage.getItem('intro')) {
            setTimeout(() => {
                setReady();
            }, 1800);
        // } else {
        //     localStorage.setItem('intro', 'true');
            // html.classList.add('has-intro');

            // setTimeout(() => {
                // setReady();
            // }, 6100);
        // }
    }
}

function setReady() {
    html.classList.add('is-animated');
    html.classList.add('is-ready'); // Duplicated for IE
    html.classList.remove('is-loading');
}
