import { module } from 'modujs';

/**
 * Module to trigger the {@see Modal}.
 *
 * @property {string}        modalName - The target modal module class name.
 * @property {string|number} modalId   - The target modal module ID.
 */
export default class extends module
{
    /**
     * Creates a new Modal Trigger.
     *
     * @param  {object} options      - The module options.
     * @param  {string} options.name - The module class name.
     * @throws {TypeError} If the module ID or module class is missing.
     */
    constructor(options) {
        super(options);

        this.events = {
            click: {
                dismiss: 'onHide',
                hide:    'onHide',
                show:    'onShow',
                toggle:  'onToggle',
            },
        };

        const moduleParts = this.getData('target').split(/[,\s]+/);

        if (moduleParts.length === 2) {
            this.modalName = moduleParts[0];
            this.modalId   = moduleParts[1];
        } else {
            this.modalName = 'Modal';
            this.modalId   = moduleParts[0];
        }

        if (!this.modalId) {
            throw new TypeError(`${options.name} must have a module ID on attribute ${this.mAttr}-target`)
        }
    }

    /**
     * Handles the hiding of the modal.
     *
     * @param  {Event} event - The click event.
     * @return {void}
     */
    onHide(event) {
        this.call('onHide', event, this.modalName, this.modalId);
    }

    /**
     * Handles the showing of the modal.
     *
     * @param  {Event} event - The click event.
     * @return {void}
     */
    onShow(event) {
        this.call('onShow', event, this.modalName, this.modalId);
    }

    /**
     * Handles the toggling of the modal.
     *
     * @param  {Event} event - The click event.
     * @return {void}
     */
    onToggle(event) {
        this.call('onToggle', event, this.modalName, this.modalId);
    }
}
