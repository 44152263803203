import { default as Modal } from './Modal';
import { isDebug } from '../utils/environment';

/**
 * The modal options when showing the modal.
 *
 * @typedef {object} ShowEmbedOptions
 *
 * @property {string} url      - The URL to embed.
 * @property {string} html     - The embed to display.
 * @property {string} provider - The embedded media's identifier.
 */

/**
 * Module to display embedded media in a modal.
 *
 * Most likely triggered by {@see EmbedModalTrigger}.
 *
 * ```html
 * <!-- Modal trigger -->
 * <button type="button"
 *         data-module-embed-modal-trigger
 *         data-embed-modal-trigger-target="embed"
 *         data-embed-modal-trigger="toggle"
 *         data-embed-modal-url="https://..."
 *         data-embed-modal-html="&lt;iframe src=&quot;https://...&quot;&gt;&lt;/iframe&gt;"
 *         data-embed-modal-provider="youtube">
 *     Launch modal
 * </button>
 *
 * <!-- Modal -->
 * <div class="modal" data-module-embed-modal="embed" tabindex="-1" aria-hidden="true">
 *     <button type="button" class="modal-close" data-embed-modal="dismiss">
 *         Close
 *     </button>
 *     <div class="modal-content" data-embed-modal="body">
 *         <!-- Modal dialog -->
 *     </div>
 * </div>
 * ```
 *
 * @todo Add support for `data-embed-module-url`.
 *
 * @property {number} insertTimeout - The timeout ID to inject the modal body before showing the modal.
 * @property {number} flushTimeout  - The timeout ID to flush the modal body after hidding the modal.
 */
export default class extends Modal
{
    /**
     * Creates a new Embed Modal.
     *
     * @param {object} options - The module options.
     */
    constructor(options) {
        super(options);

        this.insertTimeout;
        this.flushTimeout;
    }

    /**
     * Extracts the embeded media options from the event target.
     *
     * @override Modal#getShowOptionsFromTrigger
     *
     * @param  {EventTarget} target - The event target element.
     * @throws {TypeError} If options are invalid or missing.
     * @return {ShowEmbedOptions}
     */
    getShowOptionsFromTrigger(target) {
        const provider = this.getData('provider', target);
        const html     = this.getData('html', target);
        const url      = this.getData('url', target);

        if (!html && !url) {
            throw new TypeError(
                `${this.moduleName} expects an HTML fragment on attribute ${this.mAttr}-html or a URL on attribute ${this.mAttr}-url`
            );
        }

        return { url, html, provider };
    }

    /**
     * Hides the modal and empties the body.
     *
     * @override Modal#hide
     *
     * @return {void}
     */
    hide() {
        isDebug && console.group(`[App.${this.moduleName}.hide]`, 'Hiding');

        if (!this.isShown || this.isTransitioning) {
            isDebug && console.groupEnd();
            return;
        }

        if (this.once && !this.wasShown) {
            this._dismiss();
        }

        this.isTransitioning = true;

        if (this.insertTimeout) {
            clearTimeout(this.insertTimeout);
        }

        super._hide();

        this.flushTimeout = setTimeout(() => {
            this.body.innerHTML = '';
        }, 250);

        isDebug && console.groupEnd();
    }

    /**
     * Shows the modal with the given body.
     *
     * @override Modal#show
     *
     * @param  {ShowEmbedOptions} options - The modal options.
     * @return {void}
     */
    show(options) {
        isDebug && console.group(`[App.${this.moduleName}.show]`, 'Showing');

        if (this.isShown || this.isTransitioning) {
            isDebug && console.groupEnd();
            return;
        }

        if (this.once && this.wasShown) {
            isDebug && console.groupEnd();
            return;
        }

        this.isTransitioning = true;

        if (this.flushTimeout) {
            clearTimeout(this.flushTimeout);
        }

        switch (options.provider) {
            case 'youtube':
            case 'vimeo':
                options.html = this.filterUriQueryInHtml(options.html.replace('youtube.com', 'youtube-nocookie.com'), 'src', 'autoplay=1');
                break;
        }

        this.body.innerHTML = options.html;

        this.insertTimeout = setTimeout(() => super._show(), 250);

        isDebug && console.groupEnd();
    }

    /**
     * Filters the HTML to embed.
     *
     * @param  {string} html  - The HTML to filter.
     * @param  {string} attr  - The attribute to filter in `html`.
     * @param  {string} extra - The query parameter(s) to append.
     * @return {string}
     */
    filterUriQueryInHtml(html, attr, extra) {
        let re1 = new RegExp('\\b' + extra + '\\b');
        if (re1.test(html) === false) {
            let re2 = new RegExp('\\b' + attr + '="[^"]+[\\?&][^"]+"');
            let re3 = new RegExp('\\b' + attr + '="([^"]+)"');

            if (re2.test(html) === true) {
                html = html.replace(re3, attr + '="$1&' + extra + '"');
            } else {
                html = html.replace(re3, attr + '="$1?' + extra + '"');
            }
        }

        return html;
    }
}
