import { module } from 'modujs';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);

        this.active = false
        this.inited = false
        this.direction = '';
    }

    init() {
        this.compute()

        this.checkResize = this.compute.bind(this)
        window.addEventListener('resize', this.checkResize)

        this.isMobile = !html.classList.contains('has-scroll-smooth')
    }

    compute() {
        this.vw = window.innerWidth
        this.vh = window.innerHeight
    }

    trigger(args) {
        if(this.isMobile) return

        let { obj, way } = args
        if(obj.el != this.el) return

        this.direction = args.direction

        if(way == 'enter') {
            this.obj = obj
            this.active = true

            if(!this.inited) {
                this.inited = true
                this.onScroll({ scroll: { x: 0, y: 0 } })
            }
        } else {
            this.active = false
        }
    }

    onScroll(args) {
        if(!this.active) return

        let height = this.obj.bottom - this.obj.top;
        let start = this.obj.top - this.vh
        let end = start + height + this.vh
        let progress = (args.scroll.y - start) / (end - start)
        progress = Math.min(Math.max(0, progress), 1)

        this.el.style.setProperty('--progress', progress);
    }

    destroy() {
        super.destroy()

        window.removeEventListener('resize', this.checkResize)
    }
}
