const toString = Object.prototype.toString;
const arrayLikePattern = /^\[object (?:Array|FileList)\]$/;

// thanks, http://perfectionkills.com/instanceof-considered-harmful-or-how-to-write-a-robust-isarray/
export function isArray ( thing ) {
    return toString.call( thing ) === '[object Array]';
}

export function isArrayLike ( obj ) {
    return arrayLikePattern.test( toString.call( obj ) );
}

export function isEqual ( a, b ) {
    if ( a === null && b === null ) {
        return true;
    }

    if ( typeof a === 'object' || typeof b === 'object' ) {
        return false;
    }

    return a === b;
}

// http://stackoverflow.com/questions/18082/validate-numbers-in-javascript-isnumeric
export function isNumeric ( thing ) {
    return !isNaN( parseFloat( thing ) ) && isFinite( thing );
}

export function isObject ( thing ) {
    return ( thing && toString.call( thing ) === '[object Object]' );
}

export function isFunction( thing ) {
    const getType = {};
    return thing && getType.toString.call(thing) === '[object Function]';
}

let localStorageAvailable   = null;
let sessionStorageAvailable = null;

export function isLocalStorageAvailable() {
    return localStorageAvailable ??= isStorageAvailable(window.localStorage);
}

export function isSessionStorageAvailable() {
    return sessionStorageAvailable ??= isStorageAvailable(window.sessionStorage);
}

/**
 * Detects whether a web storage API is both supported and available.
 *
 * @link     https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 * @param  {Storage} storage - The Storage instance.
 * @return {boolean} Returns TRUE if the `localStorage` API is supported and available.
 */
function isStorageAvailable(storage) {
    try {
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (error) {
        return error instanceof DOMException && (
            // everything except Firefox
            error.code === 22 ||
            // Firefox
            error.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            error.name === 'QuotaExceededError' ||
            // Firefox
            error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
};
