import { module } from 'modujs';
import { isDebug } from '../utils/environment';
import {
    createElementFromHTML,
    templateReplaceMap
} from '../utils/html';

const DRAG_ENTER_EVENTS = [ 'dragenter', 'dragover' ];
const DRAG_LEAVE_EVENTS = [ 'dragleave', 'drop' ];
const DRAG_DROP_EVENTS  = DRAG_ENTER_EVENTS.concat(DRAG_LEAVE_EVENTS);

/**
 * Form Input File
 *
 * @link https://gist.github.com/barneycarroll/8323225
 *     Validate file inputs' files' types against their `accept` attribute
 *      (where applicable) using native HTML form validation API.
 */
export default class extends module {
    constructor(m) {
        super(m);

        this.$input = this.$('input')[0];
        this.$list  = this.$('list')[0];

        if (isAdvancedUpload) {
            this.el.classList.add('has-advanced-upload');
        }

        this.maxFiles = parseInt(this.getData('max-files'));
        if (typeof this.maxFiles !== 'number') {
            this.maxFiles = this.$input.multiple ? 0 : 1;
        }

        this.templateFileHTML = this.$('file-template')[0].innerHTML.trim();

        this.events = {
            click: {
                'remove': 'onRemoveFile',
            },
        };
    }

    init() {
        if (this.$input.checkValidity === HTMLInputElement.prototype.checkValidity) {
            this.$input.checkValidity = checkValidity;
        }

        this.onChange = this.onChange.bind(this);

        this.$input.addEventListener('change', this.onChange);

        if (isAdvancedUpload) {
            this.preventDefaults = this.preventDefaults.bind(this);
            this.onDragEnter     = this.onDragEnter.bind(this);
            this.onDragLeave     = this.onDragLeave.bind(this);
            this.onDrop          = this.onDrop.bind(this);

            DRAG_DROP_EVENTS.forEach((event) => {
                this.el.addEventListener(event, this.preventDefaults, false);
            });

            DRAG_ENTER_EVENTS.forEach((event) => {
                this.el.addEventListener(event, this.onDragEnter, false);
            });

            DRAG_LEAVE_EVENTS.forEach((event) => {
                this.el.addEventListener(event, this.onDragLeave, false);
            });

            this.el.addEventListener('drop', this.onDrop, false);
        }
    }

    isMaxFilesReached() {
        return (this.maxFiles > 0 && this.$input.files.length >= this.maxFiles);
    }

    preventDefaults(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragEnter() {
        this.el.classList.add('is-dragging');
    }

    onDragLeave() {
        this.el.classList.remove('is-dragging');
    }

    /**
     * Handles the "drop" event.
     *
     * @param {DragEvent}    event              - The drag and drop interaction.
     * @param {DataTransfer} event.dataTransfer - The data that is transferred
     *     during a drag and drop interaction.
     */
    onDrop({ dataTransfer: transfer }) {
        if (this.isMaxFilesReached()) {
            window.alert(getInputMaxFilesValidityMessage());
            return;
        }

        this.$input.files = concatFileLists(
            this.$input.files,
            transfer.files
        );

        console.group('App.InputFile.onDrop')
        console.log(this.$input.files)
        console.groupEnd()

        this.onChange();
    }

    /**
     * Handles the "input" and "change" events.
     */
    onChange() {
        const warnings = [];

        let hadInvalidFileType  = false;
        let hadExceededMaxFiles = false;

        if (shouldValidateInputFileType(this.$input)) {
            const RE = getInputFileTypeValidityRegExp(this.$input);
            console.log(RE);

            this.$input.files = filterFileList(
                this.$input.files,
                (file) => {
                    console.log(file);
                    if (
                        (file.type && RE.test(file.type)) ||
                        (file.name && RE.test(file.name))
                    ) {
                        return true;
                    }

                    hadInvalidFileType = true;
                    return false;
                }
            );
        }

        if (hadInvalidFileType) {
            warnings.push(getInputFileTypeValidityMessage(this.$input));
        }

        if (this.maxFiles > 0 && this.$input.files.length > this.maxFiles) {
            hadExceededMaxFiles = true;
            this.$input.files = sliceFileList(this.$input.files, 0, this.maxFiles);
        }

        this.showFileList(this.$input.files);

        if (hadExceededMaxFiles) {
            warnings.push(getInputMaxFilesValidityMessage());
        }

        if (warnings.length) {
            window.alert(warnings.join("\n"));
        }
    }

    showFileList(files) {
        if (isDebug) {
            console.group('App.InputFile.showFileList');
            console.log(files);
            console.groupEnd();
        }

        if (files.length > 0) {
            this.el.classList.add('has-files');
        } else {
            this.el.classList.remove('has-files');
        }

        if (this.maxFiles > 0 && files.length >= this.maxFiles) {
            this.el.classList.add('has-max-files');
        } else {
            this.el.classList.remove('has-max-files');
        }

        this.$list.textContent = '';

        [ ...files ].forEach((file, index) => {
            // this.$list.innerHTML = file.name;

            file.index = index;

            const fileHTML = templateReplaceMap(this.templateFileHTML, file);
            if (fileHTML) {
                const itemElement = createElementFromHTML(fileHTML);
                if (itemElement) {
                    this.$list.appendChild(itemElement);
                } else {
                    isDebug && console.warn('[App.InputFile]', `Could not insert file ${file.name}`);
                }
            } else {
                isDebug && console.warn('[App.InputFile]', `Could not render file ${file.name}`);
            }
        });
    }

    onRemoveFile(event) {
        const button = event.currentTarget;
        const index  = parseInt(button.getAttribute('data-input-file-index'));
        if (typeof index === 'number' && index >= 0) {
            this.removeFileByIndex(index);
        } else {
            isDebug && console.warn('[App.InputFile]', `Could not remove file at index ${index}`);
        }
    }

    removeFileByIndex(index) {
        const transfer = new DataTransfer();
        const files    = this.$input.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (index !== i) {
                transfer.items.add(file);
            }
        }

        this.$input.files = transfer.files;

        this.showFileList(this.$input.files);
    }

    destroy() {
        this.$input.removeEventListener('change', this.onChange);

        if (isAdvancedUpload) {
            this.el.removeEventListener('drop', this.onDrop);

            DRAG_DROP_EVENTS.forEach((event) => {
                this.el.removeEventListener(event, this.preventDefaults);
            });

            DRAG_ENTER_EVENTS.forEach((event) => {
                this.el.removeEventListener(event, this.onDragEnter);
            });

            DRAG_LEAVE_EVENTS.forEach((event) => {
                this.el.removeEventListener(event, this.onDragLeave);
            });
        }
    }
}

/**
 * Detects whether the drag & drop API is supported.
 *
 * @link https://css-tricks.com/drag-and-drop-file-uploading/
 *
 * @constant {boolean} Returns TRUE if the API is supported.
 */
const isAdvancedUpload = (function () {
    const div = document.createElement('div');
    return (
        (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) &&
        ('FormData' in window) &&
        ('FileReader' in window)
    );
})();

/**
 * Merges two or more `FileList` objects.
 *
 * Removes duplicate files (same name, type, size, and date).
 *
 * @param  {...FileList} fileLists - Zero or more `FileList` objects.
 * @return {FileList} A new `FileList` object containing the merge files.
 */
function concatFileLists(...fileLists) {
    const transfer = new DataTransfer();
    const flags    = {};

    fileLists.forEach((files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const flag = `${file.name}:${file.type}:${file.size}:${file.lastModified}`;

            if (!flags[flag]) {
                flags[flag] = true;
                transfer.items.add(file);
            }
        }
    });

    return transfer.files;
}

/**
 * Filters the files in a `FileList` object using a callback function.
 *
 * @param  {FileList} files    - A `FileList` object.
 * @param  {function} callback - A predicate to test each file.
 * @throws {TypeError} If the callback is missing or not a function.
 * @return {FileList} A new `FileList` object containing the filtered files.
 */
function filterFileList(files, callback) {
    const transfer = new DataTransfer();

    if (typeof callback !== 'function') {
        throw new TypeError('A filter function is required');
    }

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!callback || callback(file, i, files)) {
            transfer.items.add(file);
        }
    }

    return transfer.files;
}

/**
 * Extracts a portion of the files in a `FileList` object.
 *
 * @link {https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice#parameters}
 *     For usage.
 *
 * @param  {FileList} files   - A `FileList` object.
 * @param  {number}   [start] - Zero-based index at which to start extraction.
 * @param  {number}   [end]   - Zero-based index before which to end extraction.
 * @return {FileList} A new `FileList` object containing the extracted files.
 */
function sliceFileList(files, start, end) {
    files = [ ...files ].slice(start, end);

    const transfer = new DataTransfer();

    for (let i = 0; i < files.length; i++) {
        transfer.items.add(files[i]);
    }

    return transfer.files;
}

/**
 * Checks if the input is a candidate for file type validation.
 *
 * @param  {HTMLInputElement} input - The input to check.
 * @return {boolean} Returns `true` if the input is a candidate
 *     for constraint validation.
 */
function shouldValidateInputFileType(input) {
    return (
        input instanceof HTMLInputElement &&
        input.type === 'file'             &&
        input.accept                      &&
        input.files                       &&
        input.files.length
    );
}

/**
 * Checks the input's value(s) against its file type constraints.
 *
 * @param  {HTMLInputElement} input - The input to validate.
 * @return {boolean} Returns `false` if the input does not satisfy
 *     its file type constraints.
 */
function validateInputFileType(input) {
    const RE = getInputFileTypeValidityRegExp(input);

    // Ensure each of the input's files' types conform to the above
    return Array.prototype.every.call(input.files, (file) => RE.test(file.type || file.name));
}

/**
 * Creates a `RegExp` object from the input's file type constraints.
 *
 * Convert MIME type patterns as described in the `accept` attribute
 * into a valid expression to test actual MIME-type against.
 *
 * @param  {HTMLInputElement} input - The input to use.
 * @throws {TypeError} If the input does not have an `accept` attribute.
 * @return {RegExp}
 */
function getInputFileTypeValidityRegExp(input) {
    let types = getInputAcceptedFileTypes(input);
    console.group('getInputFileTypeValidityRegExp:', types);
    types = types.map((type) => {
        return type.replace(/^(\..+)|[\/\*\.]/gi, (match, extension) => {
            if (extension) {
                return '\\' + extension + '$';
            }

            switch (match) {
                case '*':
                    return '[^\\/,]+';

                default:
                    return '\\' + match;
            }
        });
    });
    console.groupEnd();

    return new RegExp(types.join('|'), 'i');
}

/**
 * Retrieves a list of the input's accepted file types.
 *
 * @param  {HTMLInputElement} input - The input to use.
 * @throws {TypeError} If the input does not have an `accept` attribute.
 * @return {RegExp[]}
 */
function getInputAcceptedFileTypes(input) {
    if (!input.accept) {
        throw new TypeError('Input must have an accept attribute');
    }

    return input.accept.split(',').map((type) => type.trim());
}

/**
 * Retrieves a formatted custom validity message
 * for the input about its file type constraints.
 *
 * @param  {HTMLInputElement} [input] - The input to update.
 * @return {string}
 */
function getInputFileTypeValidityMessage(input) {
    if (input && input.accept && window.itiData.l10n.invalidFileExtension) {
        return window.itiData.l10n.invalidFileExtension + getInputAcceptedFileTypes(input).join(', ');
    }

    return window.itiData.l10n.illegalFileExtension || 'Please select a valid file';
}

/**
 * Retrieves a formatted custom validity message
 * for the input about its max files constraints.
 *
 * @return {string}
 */
function getInputMaxFilesValidityMessage() {
    if (window.itiData.l10n.maxFilesReached) {
        return window.itiData.l10n.maxFilesReached;
    }

    return 'Maximum number of files reached';
}

/**
 * Checks the input's value(s) against its constraints.
 *
 * @extends {HTMLInputElement.prototype.checkValidity}
 *
 * @this {HTMLInputElement}
 *
 * @return {boolean}
 */
function checkValidity() {
    if (shouldValidateInputFileType(this) && !validateInputFileType(this)) {
        this.setCustomValidity(getInputFileTypeValidityMessage(this));
    }

    // Hand back to native functionality
    return HTMLInputElement.prototype.checkValidity.apply(this);
}
