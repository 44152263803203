import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'scrollTo'
        }
    }

    scrollTo(e) {
        e.preventDefault();
        const target = this.getData('href', e.curTarget);

        this.call('scrollTo', { target }, 'Scroll');
    }
}
