import { module } from 'modujs';
import lottieLight from 'lottie-web/build/player/lottie_light';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        if (!window.itiData || !window.itiData.theme_url) {
            return;
        }

        lottieLight.loadAnimation({
            container: this.el,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: window.itiData.theme_url + '/assets/motions/iti-loader.json',
            rendererSettings: {
                preserveAspectRatio: 'xMinYMin slice'
            }
        });

        lottieLight.setSpeed(1.5);
    }
}
