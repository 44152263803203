import { module } from 'modujs';
import Swiper, { A11y, Controller, EffectFade, Navigation } from 'swiper';

export default class extends module {
    constructor(m) {
        super(m);

        this.$container = this.$('container')[0]
        this.$aside = this.$('aside')[0]
    }

    init() {
        setTimeout(() => {
            this.carousel = new Swiper(this.$container, {
                modules: [
                    A11y,
                    Controller,
                    Navigation,
                ],
                speed: 450,
                slidesPerView: 'auto',
                threshold: 2,
                navigation: {
                    nextEl: this.$('next')[0],
                    prevEl: this.$('prev')[0],
                },
            });

            if (this.$aside) {
                this.fadeCarousel = new Swiper(this.$aside, {
                    modules: [
                        A11y,
                        EffectFade,
                    ],
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    }
                });

                this.carousel.controller.control = this.fadeCarousel
            }
        }, 100);
    }

    destroy() {
        this.carousel.destroy()
    }
}
