import { module } from 'modujs';
import modularLoad from 'modularload';
import { isDebug } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'target': 'handleClick',
            },
            submit: {
                'form': 'handleSubmit',
            }
        }
    }

    /**
     * @param  {Event} event - The DOM event object.
     * @return {void}
     */
    handleClick(event) {
        const jsonText = this.getData('data', event.curTarget);

        if (jsonText) {
            this.pushEventFromJson(jsonText)
        } else {
            isDebug && console.warn('GTM Warning: No JSON content was detected on following element', event.curTarget)
        }
    }

    /**
     * @param  {Event} event - The DOM event object.
     * @return {void}
     */
    handleSubmit(event) {
        console.log('Gtm.handleSubmit: ', event.target.tagName)
        const jsonText = this.getData('data', event.target);

        if (jsonText) {
            this.pushEventFromJson(jsonText)
        } else {
            isDebug && console.warn('GTM Warning: No JSON content was detected on following element', event.target)
        }
    }

    /**
     * @param  {string} json - The JSON-serialized GTM event data.
     * @return {void}
     */
    pushEventFromJson(json) {
        try {
            const data = JSON.parse(json)
            if (data) {
                this.pushEventData(data)
            }
        } catch (error) {
            isDebug && console.error('GTM Error:', error)
        }
    }

    /**
     * @param  {object} data - The GTM event data object.
     * @return {void}
     */
    pushEventData(data) {
        try {
            data.event = 'gtm_event'
            isDebug && console.log('GTM Event:', data)
            Array.isArray(window.dataLayer) && window.dataLayer.push(data)
        } catch (error) {
            isDebug && console.error('GTM Error:', error)
        }
    }
}
