import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'close': 'closeGetInTouch'
            }
        }
    }

    closeGetInTouch() {
        html.classList.remove('has-getInTouchOpen');
    }
}
