import { module } from 'modujs';

/**
 * Tooltips
 */
export default class extends module
{
    init()
    {
        if ( ! window.tippy ) {
            return;
        }

        setTimeout(() => {
            this.tippyInstances = tippy('.simple-footnote-preview', {
                allowHTML:   true,
                content:     itiData.l10n.loading,
                interactive: true,
                onCreate:    (instance) => {
                    instance._isFetching = false;
                    instance._hasContent = null;
                },
                onShow:      (instance) => {
                    if (instance._isFetching || instance._hasContent !== null) {
                        return;
                    }

                    instance._isFetching = true;

                    const sup  = instance.reference;
                    const link = sup.querySelector('a');

                    if (link) {
                        const itemId = link.getAttribute('href').substr(1);
                        const item   = document.getElementById(itemId);

                        if (item) {
                            const note = item.querySelector('.simple-footnote-reference-text');

                            if (note) {
                                instance.setContent(note.innerHTML);
                                instance._hasContent = true;
                                instance._isFetching = false;
                                return;
                            }
                        }
                    }

                    instance._hasContent = false;
                    instance._isFetching = false;
                    return false;
                },
            });
        }, 100);
    }

    destroy()
    {
        if (this.tippyInstances) {
            this.tippyInstances.destroy();
        }
    }
}
