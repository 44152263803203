import { module } from 'modujs';
import { isDebug } from '../utils/environment';

/**
 * Generic module to display a modal.
 *
 * Most likely triggered by {@see ModalTrigger}.
 *
 * ```html
 * <!-- Modal trigger -->
 * <button type="button"
 *         data-module-modal-trigger
 *         data-modal-trigger-target="example"
 *         data-modal-trigger="toggle">
 *     Launch modal
 * </button>
 *
 * <!-- Modal -->
 * <div class="modal" data-module-modal="example" tabindex="-1" aria-hidden="true">
 *     <button type="button" class="modal-close" data-modal="dismiss">
 *         Close
 *     </button>
 *     <div class="modal-content" data-modal="body">
 *         <!-- Modal dialog -->
 *     </div>
 * </div>
 * ```
 *
 * The modal supports being shown at most once:
 *
 * ```html
 * <div class="modal" data-module-modal="example" data-modal-once tabindex="-1" aria-hidden="true">
 * ```
 *
 * @property {string}        moduleName      - The module class name.
 * @property {string}        moduleId        - The module class instance ID.
 * @property {?string}       once            - Whether the modal can be shown at most once.
 * @property {boolean}       wasShown        - Track whether the modal was shown.
 * @property {boolean}       isShown         - Track whether the modal is shown or hidden.
 * @property {boolean}       isTransitioning - Track whether the modal is busy or not.
 * @property {HTMLElement}   body            - The modal body element.
 * @property {?HTMLElement}  lastTrigger     - The element that had focus outside the modal.
 * @property {HTMLElement[]} forms           - The form elements inside the modal.
 */
export default class extends module
{
    /**
     * Creates a new Modal.
     *
     * @param  {object} options          - The module options.
     * @param  {string} options.name     - The module class name.
     * @param  {string} options.dataName - The module data attribute name.
     * @throws {TypeError} If the module ID or module class is missing.
     */
    constructor(options) {
        super(options);

        this.events = {
            click: {
                dismiss: 'onHide',
                hide:    'onHide',
                show:    'onShow',
                toggle:  'onToggle',
            },
            submit: 'onSubmit',
            submitend: 'onHide',
        };

        const moduleAttr = 'data-module-' + options.dataName;

        this.moduleName = options.name;
        this.moduleId   = this.el.getAttribute(moduleAttr);

        if (!this.moduleId) {
            throw new TypeError(`${this.moduleName} must have an ID on attribute ${moduleAttr}`)
        }

        if (this.el.hasAttribute(`${this.mAttr}-once`)) {
            this.once = `iti.${this.moduleName}.${this.moduleId}.dismissed`;
        } else {
            this.once = null;
        }

        this.wasShown = window.localStorage.getItem(this.once);
        this.isShown = false;
        this.isTransitioning = false;

        this.body = this.$('body')[0];
        this.lastTrigger;
        this.forms;
    }

    /**
     * Extracts the modal options from the event target when hiding the modal.
     *
     * This method should be overriden to customize the behaviour
     * of the modal, such as handling the modal's body.
     *
     * @param  {EventTarget} target - The event target element.
     * @return {object}
     */
    getHideOptionsFromTrigger(/*target*/) {
        return {};
    }

    /**
     * Extracts the modal options from the event target when showing the modal.
     *
     * This method should be overriden to customize the behaviour
     * of the modal, such as handling the modal's body.
     *
     * @param  {EventTarget} target - The event target element.
     * @return {object}
     */
    getShowOptionsFromTrigger(/*target*/) {
        return {};
    }

    /**
     * Handles the hiding of the modal.
     *
     * @param  {Event} event - The click event.
     * @return {void}
     */
    onHide(event) {
        event.preventDefault();

        const options = this.getHideOptionsFromTrigger(event.currentTarget);

        this.hide(options);

        if (this.lastTrigger) {
            this.lastTrigger.focus();
            this.lastTrigger.setAttribute('aria-pressed', false);
            this.lastTrigger = null;
        }
    }

    /**
     * Handles the showing of the modal.
     *
     * @param  {Event} event - The click event.
     * @return {void}
     */
    onShow(event) {
        event.preventDefault();

        if (this.once && this.wasShown) {
            return;
        }

        const options = this.getShowOptionsFromTrigger(event.currentTarget);

        this.show(options);

        this.lastTrigger = event.currentTarget;
        this.lastTrigger.setAttribute('aria-pressed', true);
    }

    /**
     * Handles the toggling of the modal.
     *
     * @param  {Event} event - The click event.
     * @return {void}
     */
    onToggle(event) {
        event.preventDefault();

        const force = !this.isShown;

        if (force) {
            this.onShow(event);
        } else {
            this.onHide(event);
        }
    }

    /**
     * Handles form submission inside the modal.
     *
     * This event listener is used to handle forms that do not use the
     * {@see Form} module. If the form does not use the `Form` module,
     * the modal will dispatch a custom "submitend" event.
     *
     * @param  {Event} event - The submit event.
     * @return {void}
     */
    onSubmit(event) {
        if (event.target.matches(':not([data-module-form])')) {
            console.log('Modal.onSubmit')
            const submitEndEvent = new CustomEvent('submitend', {
                bubbles: true
            });
            event.target.dispatchEvent(submitEndEvent);
        }
    }

    /**
     * Hides the modal.
     *
     * This method should be overriden to customize the behaviour
     * of the modal, such as handling the modal's body.
     *
     * @param  {object} options - The modal options.
     * @return {void}
     */
    hide(/*options*/) {
        if (!this.isShown || this.isTransitioning) {
            return;
        }

        if (this.once && !this.wasShown) {
            this._dismiss();
        }

        this.isTransitioning = true;

        this._hide();
    }

    /**
     * Shows the modal.
     *
     * This method should be overriden to customize the behaviour
     * of the modal, such as handling the modal's body.
     *
     * @param  {object} options - The modal options.
     * @return {void}
     */
    show(/*options*/) {
        if (this.isShown || this.isTransitioning) {
            return;
        }

        if (this.once && this.wasShown) {
            return;
        }

        this.isTransitioning = true;

        this._show();
    }

    /**
     * Hides the modal if open or shows the modal if closed.
     *
     * @param  {boolean} [force]   - If included, turns the toggle into a one way-only operation.
     *     If set to `false`, then the modal will only be hidden.
     *     If set to `true`, then the modal will only be shown.
     * @param  {object}  [options] - The modal options.
     * @return {boolean} `true` or `false`, indicating whether the modal is shown or hidden.
     */
    toggle(force, options) {
        if (typeof force !== 'boolean') {
            force = !this.isShown;
        }

        if (force) {
            this.show(options);
            return true;
        } else {
            this.hide(options);
            return false;
        }
    }

    /**
     * Dismisses the modal.
     *
     * Marks the modal as dismissed which means it
     * should not be shown for the foreseeable future.
     *
     * @protected
     * @return {void}
     */
    _dismiss() {
        this.wasShown = true;
        window.localStorage.setItem(this.once, (new Date()).toUTCString());
    }

    /**
     * Hides the modal.
     *
     * @protected
     * @return {void}
     */
    _hide() {
        isDebug && console.log(`[App.${this.moduleName}.hide]`, 'Hidden');
        this.el.blur();
        this.el.classList.remove('is-active');

        this.isShown = false;
        this.isTransitioning = false;
    }

    /**
     * Shows the modal.
     *
     * @protected
     * @return {void}
     */
    _show() {
        isDebug && console.log(`[App.${this.moduleName}.show]`, 'Shown');
        this.el.classList.add('is-active');
        this.el.focus();

        this.isShown = true;
        this.isTransitioning = false;
    }
}
