/**
 * Form Conditional Logic
 *
 * Provides custom support for conditional logic to show/hide
 * elements based on the value of other elements.
 *
 * It is based on {@see www/mu-plugins/gravityforms/js/conditional_logic.js Gravity Forms module}.
 *
 * The module also takes into account if a target is a form control
 * that is required and will disable its requirement when hidden.
 */

import { module } from 'modujs'
import { isDebug } from '../utils/environment'

const DEBUG_INIT = false

/**
 * Common form input controls such as `<input>`, `<select>`, and `<textarea>`,
 * that support the "change", "input", and "reset" events.
 *
 * @typedef {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} HTMLFormControlElement
 */

/**
 * @typedef {number} FieldID - Either an integer or a float representing `<parent>.<child>`.
 */

/**
 * @typedef {'hide'|'show'} ActionType - Whether to hide or show a field.
 */
const ACTION_TYPE_HIDE = 'hide'
const ACTION_TYPE_SHOW = 'show'

/**
 * @typedef {'all'|'any'} LogicType - Whether all or any logic rules can apply.
 */
const LOGIC_TYPE_ALL = 'all'
const LOGIC_TYPE_ANY = 'any'

/**
 * @template T
 * @typedef {{ [index: FieldID]: T }} FieldSet
 */

/**
 * @typedef {object} LogicRule
 *
 * @property {FieldID} fieldId
 * @property {string}  operator
 * @property {any}     value
 */

/**
 * @typedef {object} LogicSet
 *
 * @property {boolean}     enabled
 * @property {ActionType}  actionType
 * @property {LogicType}   logicType
 * @property {LogicRule[]} rules
 */

/**
 * @typedef {object} FieldLogic
 *
 * @property {?LogicSet} [field]
 * @property {?LogicSet} [section]
 * @property {?object}   nextButton
 * @property {?object}   section
 */

const CHECKABLE_INPUTS = [ 'checkbox', 'radio' ]

const FORMAT_SELECTOR_MATCH_FORM_CONTROL_BY_ID = '#input_{0}_{1}_{2}'
const FORMAT_SELECTOR_MATCH_ANY_FORM_CONTROL_BY_ID = [
    'input[id="input_{0}_{1}"]',
    'input[id^="input_{0}_{1}_"]',
    'input[id^="choice_{0}_{1}_"]',
    'select#input_{0}_{1}',
    'textarea#input_{0}_{1}',
].join(',')
const SELECTOR_MATCH_ANY_DISABLED_FORM_CONTROLS = [
    'input:disabled',
    'select:disabled',
    'textarea:disabled',
].join(',')
const SELECTOR_MATCH_ANY_ENABLED_HIDDEN_FORM_CONTROLS = [
    'input[type="hidden"]:not(.gf-default-disabled)',
    'input[hidden]:not(.gf-default-disabled)',
    'select[hidden]:not(.gf-default-disabled)',
    'textarea[hidden]:not(.gf-default-disabled)',
].join(',')
const SELECTOR_MATCH_ANY_USER_FORM_CONTROLS = [
    'input:not([type="hidden"])',
    'select',
    'textarea',
].join(',')
const SELECTOR_MATCH_ANY_DATE_FORM_CONTROLS = [
    '.gfield_date_month input',
    '.gfield_date_day input',
    '.gfield_date_year input',
    '.gfield_date_dropdown_month select',
    '.gfield_date_dropdown_day select',
    '.gfield_date_dropdown_year select',
].join(',')

/**
 * Form Conditional Logic
 *
 * This module is expected to be declared on the `<form>` element.
 */
export default class extends module {
    /**
     * Tracks whether the module is busy is resolving logic rules.
     *
     * @var {boolean}
     */
    #busy = false

    constructor(m) {
        super(m)

        this.events = {
            change: 'handleControlChangeEvent',
        }

        this.formID = this.el.id.startsWith('c-form-') ? this.el.id.slice(7) : 0

        /**
         * A collection of dependents and their logic rules.
         *
         * @var {FieldSet<FieldLogic>}
         */
        this.logic = this.getJSONData('logic') ?? {}

        /**
         * A one-to-many collection of dependents such as sub-fields
         * affected by a dependency change.
         *
         * Gravity Forms uses this to handle sections as dependents:
         *
         * ```json
         * T of SectionID|FieldID
         *
         * { [ index: T ]: list<T, FieldID> }
         * ```
         *
         * @var {FieldSet<list<FieldID>>}
         */
        this.dependents = this.getJSONData('dependents') ?? {}

        /**
         * A one-to-many collection of dependencies and their dependents.
         *
         * @var {FieldSet<list<FieldID>>}
         */
        this.fields = this.getJSONData('fields') ?? {}

        /**
         * A collection of fields and their default values.
         *
         * @var {FieldSet<any>}
         */
        this.defaults = this.getJSONData('defaults') ?? {}

        DEBUG_INIT && console.group(`[FormConditionals.constructor:${this.formID}]`)
        DEBUG_INIT && (
            console.log('Logic:', this.logic),
            console.log('Dependants:', this.dependents),
            console.log('Fields:', this.fields),
            console.log('Defaults:', this.defaults)
        )
        DEBUG_INIT && console.groupEnd()
    }

    /**
     * Retrieves and the JSON string as an object.
     *
     * @param  {string}   name    - The data attribute short name.
     * @param  {?Element} context
     * @return {any}  The parsed JSON string.
     */
    getJSONData(name, context) {
        const value = this.getData(name, context)
        if (typeof value === 'string') {
            try {
                const data = JSON.parse(value)
                if (typeof data !== 'object' || data == null) {
                    DEBUG_INIT && console.error(
                        '[FormConditionals]',
                        `Expected data attribute [${name}] to return a JSON object, received ${typeof data}`
                    )
                }
                return data
            } catch (err) {
                DEBUG_INIT && console.error(
                    '[FormConditionals]',
                    `Expected data attribute [${name}] to return a valid JSON object:`,
                    err
                )
            }
        }

        return null
    }

    /**
     * Initializes the module when all other initial modules are created.
     */
    init() {
        DEBUG_INIT && console.group(`[FormConditionals.init:${this.formID}]`)
        this.applyRules(Object.keys(this.dependents))
        DEBUG_INIT && console.groupEnd()
    }

    /**
     * Applies the rules for the given field.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_apply_field_rule()`.
     *
     * @async
     * @param  {FieldID} fieldID - The field to affect.
     * @return {Promise}
     */
    async applyFieldRule(fieldID) {
        const action = this.checkFieldRule(fieldID)

        this.doFieldAction(action, fieldID)

        const conditionalLogic = this.logic?.[fieldID]
        //perform conditional logic for the next button
        if (conditionalLogic.nextButton) {
            const action = this.getFieldAction(conditionalLogic.nextButton)
            this.doNextButtonAction(action, fieldID)
        }
    }

    /**
     * Applies the rules for the following fields.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_apply_rules()`.
     *
     * @async
     * @param  {list<FieldID>} fields - The fields to affect.
     * @return {Promise}
     */
    async applyRules(fields) {
        for (const fieldID of fields) {
            this.applyFieldRule(fieldID)
        }
    }

    /**
     * Determines the action type for the given field or section.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_check_field_rule()`.
     *
     * @param  {FieldID} fieldID
     * @return {ActionType}
     */
    checkFieldRule(fieldID) {
        // If conditional logic is not specified for that field,
        // it is supposed to be displayed.
        const conditionalLogic = this.getFieldLogic(fieldID)

        if (!conditionalLogic) {
            return ACTION_TYPE_SHOW
        }

        const action = this.getFieldAction(conditionalLogic.section)

        // If section is hidden, always hide field. If section is displayed,
        // see if field is supposed to be displayed or hidden.
        if (action === ACTION_TYPE_HIDE) {
            return action
        }

        return this.getFieldAction(conditionalLogic.field)
    }

    /**
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_do_action()`.
     *
     * @param {ActionType} action          - The action to do.
     * @param {string}     targetID        - The target field container ID.
     * @param {any}        [defaultValues] - The default values.
     */
    doAction(action, targetID, defaultValues = null) {
        const target = this.el.querySelector(targetID)
        if (!target) {
            console.warn('[FormConditionals.doAction]', 'Missing target for ID:', targetID)
            return
        }

        /**
         * Do not re-enable inputs that are disabled by default.
         * Check if field's inputs have been assessed.
         * If not, add designator class so these inputs are exempted below.
         */
        if (target['gf-disabled-assessed'] == null) {
            target.querySelectorAll(SELECTOR_MATCH_ANY_DISABLED_FORM_CONTROLS).forEach(
                (control) => control.classList.add('gf-default-disabled')
            )
            target['gf-disabled-assessed'] = true
        }

        /**
         * This part is custom for Locomotive's form building approach.
         */
        if (target['gf-required-assessed'] == null) {
            target.querySelectorAll(SELECTOR_MATCH_ANY_USER_FORM_CONTROLS).forEach(
                (control) => control.defaultRequired = control.required
            )
            target['gf-required-assessed'] = true
        }

        // Honeypot should not be impacted by conditional logic.
        if (target.classList.contains('gfield--type-honeypot')) {
            return
        }

        if (action === ACTION_TYPE_SHOW) {
            this.#doShowAction(target, targetID, defaultValues)
        } else {
            this.#doHideAction(target, targetID, defaultValues)
        }
    }

    /**
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_do_field_action()`.
     *
     * Notes: Renamed `gform_submit_button_*` to `form_submit_button_*`.
     *
     * @param {ActionType} action  - The action to do on the field.
     * @param {FieldID}    fieldID - The field to affect.
     */
    doFieldAction(action, fieldID) {
        const dependentFields = this.dependents[fieldID]

        for (const dependantField of dependentFields) {
            const targetID = (fieldID === 0)
                ? `#form_submit_button_${this.formID}`
                : `#field_${this.formID}_${escapeQuerySelector(dependantField)}`
            const defaultValues = this.defaults[dependantField]

            this.doAction(action, targetID, defaultValues)
        }
    }

    /**
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_do_next_button_action()`.
     *
     * Notes: Renamed `gform_next_button_*` to `form_next_button_*`.
     *
     * @param {ActionType} action  - The action to do on the button.
     * @param {FieldID}    fieldID - The button's related field to affect.
     */
    doNextButtonAction(action, fieldID) {
        const targetID = `#gform_next_button_${this.formID}_${fieldID}`

        this.doAction(action, targetID)
    }

    /**
     * Retrieves the action type for the given field logic.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_get_field_action()`.
     *
     * @param  {?LogicSet} conditionalLogic
     * @return {ActionType}
     */
    getFieldAction(conditionalLogic) {
        if (!conditionalLogic) {
            return ACTION_TYPE_SHOW
        }

        let matches = 0
        for (const rule of conditionalLogic.rules) {
            if (this.isMatch(rule)) {
                matches++
            }
        }

        if (
            (conditionalLogic.logicType === LOGIC_TYPE_ALL && matches === conditionalLogic.rules.length) ||
            (conditionalLogic.logicType === LOGIC_TYPE_ANY  && matches > 0)
        ) {
            return conditionalLogic.actionType
        }

        return conditionalLogic.actionType === ACTION_TYPE_SHOW
            ? ACTION_TYPE_HIDE
            : ACTION_TYPE_SHOW
    }

    /**
     * Retrieves the conditional logic properties for the specified field.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_get_field_logic()`.
     *
     * @param {FieldID} fieldID - The ID of the current field.
     * @return {?FieldLogic}
     */
    getFieldLogic(fieldID) {
        const conditionalLogic = this.logic[fieldID]
        if (conditionalLogic) {
            return conditionalLogic
        }

        // Attempting to get section field conditional logic instead.
        for (const [ key, dependents ] of Object.entries(this.dependents)) {
            if (dependents.includes(fieldID)) {
                return this.logic[key]
            }
        }

        return null
    }

    /**
     * Parses the value from a given string.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_get_value()`.
     *
     * @param  {string} value
     * @return {string}
     */
    getValue(value) {
        if (!value) {
            return ''
        }

        value = value.split('|')
        return value[0]
    }

    /**
     * Listens to the 'change' event of input form controls
     * and applies any related conditional rules.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gform_input_change` action handler.
     *
     * @param {ChangeEvent<HTMLFormControlElement>} event
     */
    handleControlChangeEvent(event) {
        const control = event.target

        if (!control.name.startsWith('input_')) {
            return
        }

        // Extract the ID after "input_".
        const fieldID = control.name?.slice(6)

        const dependents = (this.fields?.[gformExtractFieldId(fieldID)] ?? [])

        if (dependents.length) {
            this.applyRules(dependents)
        }
    }

    /**
     * Check if a collection of checkable inputs has any checked,
     * or if they are all unchecked.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_is_checkable_empty()`.
     *
     * @param   {HTMLInputElement[]} inputs - A collection of inputs to check.
     * @returns {boolean}
     */
    isCheckableEmpty(inputs) {
        for (const input of inputs) {
            if (input.checked) {
                return false
            }
        }

        return true
    }

    /**
     * Evaluates if the form control's rule is a match.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_is_match()`.
     *
     * @param  {LogicRule} rule
     * @return {<type>}
     */
    isMatch(rule) {
        const inputID         = rule.fieldId
        const fieldID         = gformExtractFieldId(inputID)
        const inputIndex      = gformExtractInputIndex(inputID)
        const isInputSpecific = (inputIndex != null)

        const inputs = this.el.querySelectorAll(
            isInputSpecific
            ? gformFormat(FORMAT_SELECTOR_MATCH_FORM_CONTROL_BY_ID, this.formID, fieldID, inputIndex)
            : gformFormat(FORMAT_SELECTOR_MATCH_ANY_FORM_CONTROL_BY_ID, this.formID, fieldID)
        )

        if (!inputs.length) {
            return null // TODO
        }

        const input = inputs.item(0)

        if (input instanceof HTMLSelectElement) {
            return this.isMatchSelectable(input, rule, fieldID)
        }

        if (input.type && CHECKABLE_INPUTS.includes(input.type)) {
            return this.isMatchCheckable(inputs, rule, fieldID)
        }

        return this.isMatchDefault(input, rule, fieldID)
    }

    /**
     * Evaluates if the rule is a match for a checkable form controls (checkbox and radio).
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_is_match_checkable()`.
     *
     * @param  {HTMLInputElement[]} inputs  - The inputs.
     * @param  {LogicRule}          rule    - The field rule.
     * @param  {FieldID}            fieldID - The field ID.
     * @return {boolean}
     */
    isMatchCheckable(inputs, rule, fieldID) {
        // Rule is checking if the checkable is/isn't blank.
        // Return a specific check for that use-case.
        if (rule.value === '') {
            if (rule.operator === 'is') {
                return this.isCheckableEmpty(inputs)
            } else {
                return !this.isCheckableEmpty(inputs)
            }
        }

        for (const input of inputs) {
            let fieldValue       = this.getValue(input.value)
            let isRangeOperator  = [ '<', '>' ].includes(rule.operator)
            let isStringOperator = [ 'contains', 'starts_with', 'ends_with' ].includes(rule.operator)

            // If we are looking for a specific value and this is not it, skip.
            if (fieldValue != rule.value && !isRangeOperator && !isStringOperator) {
                continue
            }

            if (!input.checked) {
                // Force an empty value for unchecked items.
                fieldValue = ''
            } else if (fieldValue === 'gf_other_choice') {
                // If the 'other' choice is selected, get the value from the 'other' text input.
                fieldValue = this.el.querySelector(
                    gformFormat('#input_{0}_{1}_other', this.formID, fieldID)
                )?.value
            }

            if (this.matchesOperation(fieldValue, rule.value, rule.operator)) {
                return true
            }

        }

        return false
    }

    /**
     * Evaluates if the rule is a match for a generic form control (`<input>` or `<textarea>`).
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_is_match_default()`.
     *
     * @param  {HTMLInputElement|HTMLTextAreaElement} input   - The field element.
     * @param  {LogicRule}                            rule    - The field rule.
     * @param  {FieldID}                              fieldID - The field ID.
     * @return {boolean}
     */
    isMatchDefault(input, rule, fieldID) {
        const value = input.value

        // Fields with pipes in the value will use the label for conditional logic comparison.
        const hasLabel = (value ? (value.indexOf('|') >= 0) : true)
        let fieldValue = this.getValue(value)

        const fieldNumberFormat = gformGetFieldNumberFormat(rule.fieldId, 'value')
        if (fieldNumberFormat && !hasLabel) {
            fieldValue = gformFormatNumber(fieldValue, fieldNumberFormat)
        }

        const ruleValue = rule.value
        // const ruleValue = fieldNumberFormat
        //     ? gformFormatNumber( ruleValue, fieldNumberFormat )
        //     : rule.value

        if (this.matchesOperation(fieldValue, ruleValue, rule.operator)) {
            return true
        }

        return false
    }

    /**
     * Evaluates if the rule is a match for a `<select>` form control.
     *
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_is_match_default()`.
     *
     * @param  {HTMLSelectElement} input   - The field element.
     * @param  {LogicRule}         rule    - The field rule.
     * @param  {FieldID}           fieldID - The field ID.
     * @return {boolean}
     */
    isMatchSelectable(input, rule, fieldID) {
        const values = input.selectedOptions.length
            ? Array.from(input.selectedOptions).map((option) => option.value)
            : [ '' ]

        let matchCount = 0

        for (const value of values) {
            // Fields with pipes in the value will use the label for conditional logic comparison.
            const hasLabel = (value ? (value.indexOf('|') >= 0) : true)
            let fieldValue = this.getValue(value)

            const fieldNumberFormat = gformGetFieldNumberFormat(rule.fieldId, 'value')
            if (fieldNumberFormat && !hasLabel) {
                fieldValue = gformFormatNumber(fieldValue, fieldNumberFormat)
            }

            const ruleValue = rule.value
            // const ruleValue = fieldNumberFormat
            //     ? gformFormatNumber( ruleValue, fieldNumberFormat )
            //     : rule.value

            if (this.matchesOperation(fieldValue, ruleValue, rule.operator)) {
                matchCount++
            }
        }

        // If operator is 'isnot', none of the values can match.
        if (rule.operator === 'isnot') {
            return (matchCount === values.length)
        }

        return (matchCount > 0)
    }

    /**
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_matches_operation()`.
     *
     * @param  {number|string} val1      - The input value.
     * @param  {number|string} val2      - The rule value.
     * @param  {string}        operation - The rule operator.
     * @return {boolean}
     */
    matchesOperation(val1, val2, operation) {
        val1 = val1 ? val1.toLowerCase() : ''
        val2 = val2 ? val2.toLowerCase() : ''

        switch (operation) {
            case 'is': {
                return (val1 == val2)
            }

            case 'isnot': {
                return (val1 != val2)
            }

            case '>': {
                val1 = gformTryConvertFloat(val1)
                val2 = gformTryConvertFloat(val2)

                return (gformIsNumber(val1) && gformIsNumber(val2))
                    ? (val1 > val2)
                    : false
            }

            case '<': {
                val1 = gformTryConvertFloat(val1)
                val2 = gformTryConvertFloat(val2)

                return (gformIsNumber(val1) && gformIsNumber(val2))
                    ? (val1 < val2)
                    : false
            }

            case 'contains': {
                return (val1.indexOf(val2) >= 0)
            }

            case 'starts_with': {
                return (val1.indexOf(val2) === 0)
            }

            case 'ends_with': {
                const start = (val1.length - val2.length)
                if (start < 0) {
                    return false
                }

                const tail = val1.substring(start)
                return (val2 == tail)
            }
        }

        return false
    }

    /**
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_reset_to_default()`.
     *
     * Note: Triggers 'change' event instead of 'click' event,
     *
     * @param {HTMLElement} target       - The target field container element.
     * @param {string}      targetID     - The target field container ID.
     * @param {any}         defaultValue - The default value.
     */
    resetToDefault(target, targetID, defaultValue) {
        /** @var {HTMLInputElement|HTMLSelectElement} dateFields */
        const dateControls = target.querySelectorAll(SELECTOR_MATCH_ANY_DATE_FORM_CONTROLS)
        if (dateControls.length > 0) {
            dateControls.forEach((control) => {
                let value = ''

                if (defaultValue) {
                    const parent = element.closest('.c-form_item')
                    if (parent) {
                        let key = 'd'

                        if (
                            parent.classList.contains('gfield_date_month') ||
                            parent.classList.contains('gfield_date_dropdown_month')
                        ) {
                            key = 'm'
                        } else if (
                            parent.classList.contains('gfield_date_year') ||
                            parent.classList.contains('gfield_date_dropdown_year')
                        ) {
                            key = 'y'
                        }

                        value = defaultValue[key]
                    }
                }

                if (control instanceof HTMLSelectElement && value !== '') {
                    value = parseInt(value, 10)
                }

                if (control.value !== value) {
                    control.value = value
                    control.dispatchEvent(new Event('change', { bubbles: true }))
                }
            })
            return
        }

        // Cascading down conditional logic to children to support nested conditions
        // text fields and drop downs, filter out list field text fields name with "_shim".
        const controls = target.querySelectorAll(
            'select, input[type="text"]:not([id*="_shim"]), input[type="number"], input[type="hidden"], input[type="email"], input[type="tel"], input[type="url"], textarea'
        )
        if (controls.length > 0) {
            let controlIndex = 0

            /**
             * @todo Implement list field handling.
             */

            controls.forEach((control) => {
                let value = ''

                if (control.type === 'hidden' /** @todo Implement condition `&& !gf_is_hidden_pricing_input(control)`` */) {
                    return
                }

                /**
                 * @todo Implement "Other" choice.
                 */

                // Get name of previous input field to see if it is the
                // radio button which goes with the "Other" text box
                // otherwise field is populated with input field name.
                const radioButtonName = control.previousElementSibling?.value
                if (radioButtonName === 'gf_other_choice') {
                    value = control.value
                } else if (Array.isArray(defaultValue) && !((control instanceof HTMLSelectElement) && control.multiple)) {
                    value = defaultValue[controlIndex]
                } else if (typeof defaultValue === 'object' && defaultValue != null) {
                    value = defaultValue[control.name]

                    if (!value && control.id) {
                        // 'input_123_3_1' => '3.1'
                        const inputID = control.id.split('_').slice(2).join(1)
                        value = defaultValue[inputID]
                    }

                    if (!value && control.name) {
                        const inputID = control.name.split('_')[1]
                        value = defaultValue[inputID]
                    }
                } else if (defaultValue) {
                    value = defaultValue
                }

                if ((control instanceof HTMLSelectElement) && !control.multiple && !value) {
                    value = control.querySelector('option:not(:disabled)')?.value
                }

                if (control.value !== value) {
                    control.value = value
                    control.dispatchEvent(new Event('change', { bubbles: true }))
                }

                controlIndex++
            })
        }

        //checkboxes and radio buttons
        const checkableControls = target.querySelectorAll(
            'input[type="radio"], input[type="checkbox"]:not(.copy_values_activated)'
        )
        checkableControls.forEach((control) => {
            // Is input currently checked?
            const isChecked = control.checked

            // Does input need to be marked as checked or unchecked?
            const doCheck = (defaultValue?.includes(control.id) ?? false)

            // If value changed, trigger click event.
            if (isChecked !== doCheck) {
                // Setting input as checked or unchecked appropriately.
                control.checked = doCheck
                control.dispatchEvent(new Event('change', { bubbles: true }))
            }
        })
    }

    /**
     * In case of use of locomotive-scroll, we need to update page height
     */
    updateScroll() {
        requestAnimationFrame(() => {
            this.call('update', 'Scroll')
        })
    }

    /**
     * @see this.doAction
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_do_action()`.
     *
     * @param {HTMLElement} target          - The target field container element.
     * @param {string}      targetID        - The target field container ID.
     * @param {any}         [defaultValues] - The default values.
     */
    #doHideAction(target, targetID, defaultValues = null) {
        // If field is not already hidden, reset its values to the default.
        const child = target.children[0]
        if (child) {
            if (!gformIsHidden(child)) {
                this.resetToDefault(target, targetID, defaultValues)
            }
        }

        target.querySelectorAll(SELECTOR_MATCH_ANY_ENABLED_HIDDEN_FORM_CONTROLS).forEach(
            (control) => control.disabled = true
        )

        target.querySelectorAll(SELECTOR_MATCH_ANY_USER_FORM_CONTROLS).forEach(
            (control) => control.required = false
        )

        // Handle conditional submit and next buttons.
        if (target.type === 'submit' || target.classList.contains('gform_next_button')) {
            target.disabled = true
        }

        target.hidden = true
    }

    /**
     * @see this.doAction
     * @see www/mu-plugins/gravityforms/js/conditional_logic.js
     *     Based on `gf_do_action()`.
     *
     * @param {HTMLElement} target          - The target field container element.
     * @param {string}      targetID        - The target field container ID.
     * @param {any}         [defaultValues] - The default values.
     */
    #doShowAction(target, targetID, defaultValues = null) {
        target.querySelectorAll(SELECTOR_MATCH_ANY_ENABLED_HIDDEN_FORM_CONTROLS).forEach(
            (control) => control.disabled = false
        )

        target.querySelectorAll(SELECTOR_MATCH_ANY_USER_FORM_CONTROLS).forEach(
            (control) => control.required = control.defaultRequired
        )

        // Handle conditional submit and next buttons.
        if (target.type === 'submit' || target.classList.contains('gform_next_button')) {
            target.disabled = false
        }

        target.hidden = false
    }
}

function escapeQuerySelector(query) {
    return (query + '').replace(/[\\\/.]/g, '\\$&');
}
/**
 * Gets a formatted number and returns a clean "decimal dot" number.
 *
 * Note: Input must be formatted according to the specified parameters (symbolRight, symbolLeft, decimalSeparator).
 *
 * @example input -> $1.20, output -> 1.2
 *
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformCleanNumber()`.
 *
 * @param  {string} text             - The currency-formatted number.
 * @param  {string} symbolRight      - The symbol used on the right.
 * @param  {string} symbolLeft       - The symbol used on the left.
 * @param  {string} decimalSeparator - The decimal separator being used.
 * @return {?number} The unformatted numerical value.
 */
function gformCleanNumber(text, symbolRight, symbolLeft, decimalSeparator) {
    let cleanNumber = ''
    let floatNumber = ''
    let isNegative  = false

    // Converting to a string if a number is passed.
    text = text + ''

    // Removing unicode tokens (i.e. "&#4444;")
    text = text.replace(/&.*?;/g, '')

    // Removing symbol from text (i.e. "$").
    text = text.replace(symbolRight, '')
    text = text.replace(symbolLeft, '')

    // Removing all non-numeric characters.
    for (const digit of text) {
        if (digit === '-') {
            isNegative = true
            continue
        } else if (digit === decimalSeparator) {
            cleanNumber += digit
            continue
        } else {
            const integer = parseInt(digit, 10)
            if (integer >= 0 && integer <= 9) {
                cleanNumber += digit
            }
        }
    }

    // Removing thousand separators but keeping decimal point.
    for (const digit of cleanNumber) {
        if (digit === decimalSeparator) {
            floatNumber += '.'
        } else {
            const integer = parseInt(digit, 10)
            if (integer >= 0 && integer <= 9) {
                floatNumber += digit
            }
        }
    }

    if (isNegative) {
        floatNumber = '-' + floatNumber
    }

    return gformIsNumber(floatNumber)
        ? parseFloat(floatNumber)
        : null
}

/**
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformExtractFieldId()`.
 *
 * @param  {number} inputID - The input ID to parse.
 * @return {number|string} Either the inputID or the fieldID.
 */
function gformExtractFieldId(inputID) {
    const fieldID = parseInt(inputID.toString().split('.')[0], 10)
    return fieldID || inputID
}

/**
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformExtractInputIndex()`.
 *
 * @param  {number} inputID - The input ID to parse.
 * @return {?number} Either the index of the inputID or `null`.
 */
function gformExtractInputIndex(inputID) {
    const inputIndex = parseInt(inputID.toString().split('.')[1], 10)
    return inputIndex || null
}

/**
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `String.prototype.gformFormat()`.
 *
 * @return {string} ...str  - The string to format.
 * @return {any}    ...args - One or more replacements.
 */
function gformFormat(str, ...args) {
    return str.replace(
        /{(\d+)}/g,
        (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match)
    )
}

/**
 * @see www/mu-plugins/gravityforms/js/conditional_logic.js
 *     Based on `gf_format_number()`.
 *
 * @param  {number|string} value
 * @param  {string}        fieldNumberFormat
 * @return string}
 */
function gformFormatNumber(value, fieldNumberFormat) {
    const decimalSeparator = gformGetDecimalSeparator(fieldNumberFormat)

    // Transform to a decimal dot number.
    value = gformCleanNumber(value, '', '', decimalSeparator)

    return (value ? value.toString() : '0')
}

/**
 * @todo Implement `Currency` class from
 *     {@see www/mu-plugins/gravityforms/js/gravityforms.js}.
 *
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformGetDecimalSeparator()`.
 *
 * @param  {string} numberFormat
 * @return {string}
 */
function gformGetDecimalSeparator(numberFormat) {
    if (numberFormat === 'currency') {
        // const currency = new Currency(gf_global.gf_currency_config)
        // return currency.currency.decimal_separator
        return '.'
    }

    if (numberFormat === 'decimal_comma') {
        return ','
    }

    return '.'
}

/**
 * @todo Implement `gf_global` global JS variable.
 *
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gf_get_field_number_format()`.
 *
 * @param  {FieldID} fieldId
 * @param  {number}  formId
 * @param  {string}  context
 * @return {?string}
 */
function gformGetFieldNumberFormat(fieldId, formId, context) {
    return null

    // const fieldNumberFormats = rgars(window, 'gf_global/number_formats/{0}/{1}'.gformFormat(formId, fieldId))

    // if (!fieldNumberFormats) {
    //     return null
    // }

    // if (typeof context === 'undefined') {
    //     return fieldNumberFormats.price !== false
    //         ? fieldNumberFormats.price
    //         : fieldNumberFormats.value
    // }

    // return fieldNumberFormats[context]
}

/**
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformIsHidden()`.
 *
 * Note: Renamed `.gfield` to `.c-form_item`.
 *
 * @param  {HTMLElement} element
 * @return {boolean}
 */
function gformIsHidden(element) {
    const parent = element.closest('.c-form_item')
    if (!parent) {
        return false
    }

    if (parent.classList.contains('gfield_hidden_product')) {
        return false
    }

    return parent.hidden === true
}

/**
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformIsNumber()`.
 *
 * @param  {any} n
 * @return {boolean}
 */
function gformIsNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 * @see www/mu-plugins/gravityforms/js/gravityforms.js
 *     Based on `gformIsNumeric()`.
 *
 * @param  {string} value
 * @param  {string} numberFormat
 * @return {boolean}
 */
function gformIsNumeric(value, numberFormat) {
    if (numberFormat === 'decimal_dot') {
        return /^(-?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]+)?)$/.test(value)
    }

    if (numberFormat === 'decimal_comma') {
        return /^(-?[0-9]{1,3}(?:\.?[0-9]{3})*(?:,[0-9]+)?)$/.test(value)
    }

    return false
}

/**
 * @see www/mu-plugins/gravityforms/js/conditional_logic.js
 *     Based on `gf_try_convert_float()`.
 *
 * @param  {string} text
 * @return {number|string}
 */
function gformTryConvertFloat(text) {
    /**
     * The only format that should matter is the field format.
     * Attempting to do this by WP locale creates a lot of issues with consistency.
     * ```
     * var format = window["gf_number_format"] == "decimal_comma" ? "decimal_comma" : "decimal_dot";
     * ```
     */
    const format = 'decimal_dot'
    if (gformIsNumeric(text, format)) {
        const decimalSeparator = format === 'decimal_comma' ? ',' : '.'
        return gformCleanNumber(text, '', '', decimalSeparator)
    }

    return text
}
