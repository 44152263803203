import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'close': 'closeNav',
                'getInTouch': 'openGetInTouch'
            }
        }
    }

    closeNav() {
        html.classList.remove('has-navOpen');
    }

    openGetInTouch() {
        console.log('ici')
        html.classList.add('has-getInTouchOpen');
    }
}
