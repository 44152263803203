import { module } from 'modujs';
import lottieLight from 'lottie-web/build/player/lottie_light';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        if (!window.itiData || !window.itiData.theme_url) {
            return;
        }

        this.transition = lottieLight.loadAnimation({
            container: this.el,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: window.itiData.theme_url + '/assets/motions/iti-transition.json',
            rendererSettings: {
                preserveAspectRatio: 'xMinYMin slice'
            }
        });
    }

    start() {
        this.transition.goToAndPlay(800);
    }

    stop() {
        setTimeout(() => {
            this.transition.stop();
        }, 750);
    }
}
