import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const choices = this.getChoices();

        if (choices.length) {
            const choice = choices[Math.floor(Math.random() * choices.length)];

            this.el.setAttribute('src', choice);
        } else {
            const src = this.getData('fallback');
            const num = this.getRandomInt(1, 6);

            this.el.setAttribute('src', `${src}0${num}.png`);
        }
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getChoices() {
        const json = this.getData('choices');

        if (json) {
            try {
                const choices = JSON.parse(json)
                if (Array.isArray(choices)) {
                    return choices;
                }
            } catch (error) {
                isDebug && console.warn('Using default random images:', error)
            }
        }

        return [];
    }
}
