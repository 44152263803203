import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.logoScale = false;
        this.textScale = false;

        if ((window.navigator.userAgent.match(/MSIE|Trident/) !== null)) {
            this.scroll = new LocomotiveScroll({
                el: this.el,
                smooth: false,
                getDirection: true
            });
        } else {
            this.scroll = new LocomotiveScroll({
                el: this.el,
                smooth: true,
                getDirection: true
            });
        }


        this.smooth = html.classList.contains('has-scroll-smooth');

        this.scroll.on('call', (func, way, obj, id) => {
            if (func === 'logoScale') {
                if (way === 'enter') {
                    this.logoScale = obj;
                } else {
                    this.logoScale = false;
                }
            } else if (func === 'textScale') {
                if (way === 'enter') {
                    this.textScale = obj;
                } else {
                    this.textScale = false;
                }
            } else {
                this.call(func[0], { way, obj }, func[1], func[2]);
            }
        });

        this.scroll.on('scroll', (instance) => {
            this.instance = instance;

            if(instance.scroll.y > 50) {
                html.classList.add('has-scrolled');
            } else {
                html.classList.remove('has-scrolled');
            }

            if(instance.direction == 'down') {
                html.classList.add('is-scrolling-down')
            } else {
                html.classList.remove('is-scrolling-down')
            }

             // RAIL
             if(this.smooth)
                this.call('onScroll', instance, 'Rail');

            this.call('onScroll', instance, 'AnimateSection');
            this.call('onScroll', instance, 'Collective');

            this.logoScale && this.scaleLogo()
            this.textScale && this.scaleText()

            this.call('onScroll', instance.scroll.y, 'StickyNav');
        });
    }

    forceElementDetect(el) {
        if(!this.smooth) return;

        const elObj = this.scroll?.scroll?.els?.find?.(item => item.el == el);
        const elIndex = this.scroll?.scroll?.els?.indexOf?.(elObj);

        this.scroll?.scroll?.setInView?.(elObj, elIndex);
    }

    scaleLogo() {
        const start = this.logoScale.top;
        const progress = Math.max(0,Math.min((this.instance.scroll.y - start) / 1500, 1));

        const scale = 1 - progress;

        if (scale > 0.28) {
            this.logoScale.el.style.transform = 'scale('+scale+')';
        }
    }

    scaleText() {
        const start = this.textScale.top;
        const progressScale = Math.max(0,Math.min((this.instance.scroll.y - start) / 1100, 1));
        const progressOpacity = Math.max(0,Math.min((this.instance.scroll.y - start) / 800, 1));

        const scale = 1 - progressScale;
        const opacity = 1 - progressOpacity;

        if (scale >= 0) {
            this.textScale.el.style.transform = 'scale('+scale+')';
            this.textScale.el.style.opacity = opacity;
        }
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if(src.length) {
            if(args.obj.el.tagName == "IMG") {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url(${src})`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    update() {
        this.scroll.update();
    }

    scrollTo(options) {
        this.scroll.scrollTo(options.target, -70);
    }

    destroy() {
        this.scroll.destroy();
    }
}
